import { RootStore } from "stores";
import { observable, makeObservable, action, runInAction } from "mobx";
import { CustomerList, PaginationConfig } from "types/fxm";
import { DynamicQueryParameters } from "lib/apiClient";

export default class LesseesPageStore {
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      lessees: observable,
      pagination: observable,
      getCustomers: action
    });

    this.rootStore = rootStore;
  }

  rootStore: RootStore;
  lessees: CustomerList[] = [];
  pagination?: PaginationConfig;

  async getCustomers(search?: string, query?: DynamicQueryParameters) {
    let response = await this.rootStore.apiClient.requestLink<CustomerList[]>(
      this.rootStore.userStore.resources["customers"],
      { queryParams: { search }, query },
      { lessorNamespace: this.rootStore.routerStore.lessor }
    );

    runInAction(() => {
      this.lessees = response.payload!;
      this.pagination = response.pagination;
    });
  }
}
