import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.less";

type TextEditorProps = {
  value: string | undefined;
  setFieldValue: (val: string) => void;
};

export const TextEditor = ({ value, setFieldValue }: TextEditorProps) => {
  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
  };

  return (
    <Editor
      editorState={editorState}
      toolbarClassName="text-editor-toolbar"
      wrapperClassName="text-editor-wrapper"
      editorClassName="text-editor"
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ["blockType", "inline", "list", "link"],
        inline: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ["bold", "italic", "underline", "strikethrough"]
        },
        blockType: {
          inDropdown: true,
          options: ["Normal", "H1", "H2"],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined
        }
      }}
    />
  );
};
