import React from "react";
import Table, { TableActions } from "components/table";
import { Page, Date, Number, Link, ButtonLink, Dropdown } from "components";
import {
  PaymentStateLabel,
  QuickPayPaymentStateLabel,
  LeverandorServicePaymentStateLabel
} from "components/payments";
import {
  QuickPayOperationStatus,
  PaymentListEntry,
  LinkModel
} from "types/fxm";
import { ColumnProps } from "antd/lib/table";
import { RootStore, PaymentStore, NotificationsStore } from "stores";
import { connect, observer, ConnectedComponent, toJS } from "lib/mobx";
import { DynamicQueryParameters } from "lib/apiClient";
import { RouteName } from "sitemap";
import { Menu, Modal } from "antd";
import PaymentsPageStore from "./store";
import { paymentGatewayTranslations, paymentStateTranslations } from "lib/intl";

interface State {
  loading: boolean;
  query?: DynamicQueryParameters;
  search?: string;
}

interface Stores {
  rootStore: RootStore;
  paymentStore: PaymentStore;
  notificationsStore: NotificationsStore;
}

@connect(
  "rootStore",
  "paymentStore",
  "notificationsStore"
)
@observer
export default class PaymentsPage extends ConnectedComponent<
  any,
  Stores,
  State
> {
  state: Readonly<State> = { loading: true };
  store = new PaymentsPageStore(this.stores.rootStore);
  table = React.createRef<Table<PaymentListEntry>>();
  defaultTableDynamicQuery = {
    filters: [{ field: "state", values: ["Initiated", "Approved"] }]
  };

  componentDidMount() {
    this.handleTableRequest();
  }

  handleTableRequest = async (query?: DynamicQueryParameters) => {
    this.setState(s => ({ ...s, loading: true, query }));

    if (!query && this.table.current) {
      this.table.current.resetSortAndFilters();
      query = this.defaultTableDynamicQuery;
    }

    await this.store.getPayments(this.state.search, query);

    this.setState(s => ({ ...s, loading: false }));
  };

  handleSearch = async (value: string) => {
    await this.setState(s => ({ ...s, search: value }));
    await this.handleTableRequest();
  };

  handleClearFilters = async () => {
    await this.setState(s => ({ ...s, search: undefined }));
    await this.handleTableRequest();
  };

  handleApprove = async (link: LinkModel) => {
    this.setState(s => ({ ...s, loading: true }));
    await this.store.approve(link);
    this.handleTableRequest(this.state.query);
  };

  handleSetPaid = async (link: LinkModel) => {
    this.setState(s => ({ ...s, loading: true }));
    await this.store.setPaid(link);
    this.handleTableRequest(this.state.query);
  };

  handleSendReminder = async (link: LinkModel) => {
    let that = this;
    await Modal.confirm({
      title: "Ønsker du at sende en påmindelse til kunden?",
      async onOk() {
        that.setState(s => ({ ...s, loading: true }));
        await that.store.sendReminder(link);
        that.stores.notificationsStore.success("Påmindelse sendt til kunden.");
        that.handleTableRequest(that.state.query);
      },
      okText: "Send påmindelse",
      cancelText: "Annuller"
    });
  };

  handleEmailInvoice = async (link: LinkModel) => {
    let that = this;
    await Modal.confirm({
      title: "Ønsker du at sende fakturaen til kunden?",
      async onOk() {
        await that.store.emailInvoice(link);
        that.stores.notificationsStore.success("Faktura sendt til kunde.");
      },
      okText: "Send faktura",
      cancelText: "Annuller"
    });
  };

  render() {
    const { payments, pagination } = this.store;
    const { loading, search } = this.state;

    return (
      <Page
        title="Betalinger"
        table
        actions={
          <TableActions
            allowSearch
            searchWidth={300}
            searchValue={search}
            onSearchChanged={val => this.setState(s => ({ ...s, search: val }))}
            onSearch={this.handleSearch}
            searchPlaceholder="Søg på kunde nr/navn eller faktura nr."
            onClearFilters={this.handleClearFilters}
          />
        }
      >
        <Table<PaymentListEntry>
          ref={this.table}
          rowKey="id"
          loading={loading}
          onRequest={this.handleTableRequest}
          dataSource={toJS(payments)}
          defaultDynamicQuery={this.defaultTableDynamicQuery}
          columns={buildColumns(
            this.handleApprove,
            this.handleSetPaid,
            this.handleSendReminder,
            this.handleEmailInvoice,
            this.stores.paymentStore.paymentStates,
            this.stores.paymentStore.quickPayPaymentStates
          )}
          pagination={pagination}
        />
      </Page>
    );
  }
}

const buildColumns = (
  onApprove: (link: LinkModel) => void,
  onSetPaid: (link: LinkModel) => void,
  onSendReminder: (link: LinkModel) => void,
  onEmailInvoice: (link: LinkModel) => void,
  paymentStates: string[],
  quickPayPaymentStates: string[]
): ColumnProps<PaymentListEntry>[] => [
  {
    dataIndex: "customerNum",
    title: "Kunde nr.",
    className: "fit right",
    sorter: true,
    render: (v, row) => (
      <Link routeName={RouteName.Lessee} routeParams={{ id: row.partyId }}>
        {v}
      </Link>
    )
  },
  {
    dataIndex: "partyName",
    title: "Kunde",
    sorter: true
  },
  {
    dataIndex: "invoiceNum",
    title: "Faktura nr.",
    className: "right",
    sorter: true
  },
  {
    dataIndex: "dueDate",
    title: "Forfaldsdato",
    className: "right fit",
    sorter: true,
    render: v => <Date value={v} />
  },
  {
    title: "LS Betalingsdato",
    className: "right fit",
    sorter: true,
    render: (v, r) =>
      r.leverandorService && <Date value={r.leverandorService.paymentDate} />
  },
  {
    dataIndex: "totalAmount",
    title: "Beløb",
    className: "right",
    sorter: true,
    render: v => <Number value={v} />
  },
  {
    dataIndex: "state",
    title: "Betalingsstatus",
    className: "fit center",
    sorter: true,
    render: v => <PaymentStateLabel tag state={v} />,
    filters: paymentStates.map(x => ({
      value: x,
      text: paymentStateTranslations[x]
    }))
  },
  {
    dataIndex: "paymentGateway",
    title: "Udbyder",
    className: "fit",
    sorter: true,
    render: v => paymentGatewayTranslations[v],
    filters: Object.keys(paymentGatewayTranslations).map(x => ({
      value: x,
      text: paymentGatewayTranslations[x]
    }))
  },
  {
    title: "Udbyderstatus",
    className: "fit center",
    render: (_, row) => {
      if (row.paymentState === "Ignored") {
        return paymentStateTranslations[row.paymentState];
      } else if (row.paymentGateway === "QuickPay" && row.quickPay)
        return (
          <QuickPayPaymentStateLabel
            tag
            paymentState={row.quickPay.paymentState}
            subscriptionState={row.quickPay.subscriptionState}
            style={{ width: 130 }}
          />
        );
      else if (
        row.paymentGateway === "LeverandorService" &&
        row.leverandorService
      ) {
        return (
          <LeverandorServicePaymentStateLabel
            tag
            paymentState={row.leverandorService.paymentState}
            subscriptionState={row.leverandorService.subscriptionState}
            style={{ width: 130 }}
          />
        );
      } else return null;
    }
  },
  {
    title: "Årsag",
    render: (_, row) => {
      if (row.paymentGateway === "QuickPay" && row.quickPay) {
        if (
          row.quickPay.paymentState === "Initiated" &&
          row.quickPay.subscriptionState === "Initiated"
        ) {
          return <span>Betalingskort ikke tilmeldt.</span>;
        } else if (row.quickPay.paymentState === "AuthorizationFailed") {
          return (
            <QuickPayOperationStatusLabel
              status={row.quickPay.authorizeError}
            />
          );
        } else if (row.quickPay.paymentState === "CaptureFailed") {
          return (
            <QuickPayOperationStatusLabel status={row.quickPay.captureError} />
          );
        }
      } else if (
        row.paymentGateway === "LeverandorService" &&
        row.leverandorService
      ) {
        if (row.leverandorService.paymentState === "Cancelled") {
          return null;
        }
        if (row.leverandorService.paymentState === "Failed") {
          return <span>{row.leverandorService.failedMessage}</span>;
        }
        if (row.leverandorService.subscriptionState === "Initiated") {
          return (
            <span title="Afventer at kunden tilmelder sig LeverandørService.">
              Bankkonto ikke tilmeldt.
            </span>
          );
        }
        if (
          row.leverandorService.subscriptionState === "AwaitingRegistration"
        ) {
          return (
            <span title="Kunden har tilmeldt sig LeverandørService, men tilmeldingen er endnu ikke blevet bekræftet af LeverandørService.">
              Afventer bekræftelse på tilmelding.
            </span>
          );
        }
      } else return null;
    }
  },
  {
    className: "fit",
    render: (_, row) => {
      let items = new Array<JSX.Element>();

      if (row.links.approve) {
        items = [
          ...items,
          <Menu.Item key="approve">
            <ButtonLink
              onClick={() => onApprove(row.links.approve)}
              disabled={!row.links.approve}
            >
              Godkend udbetaling
            </ButtonLink>
          </Menu.Item>
        ];
      }

      items = [
        ...items,
        <Menu.Item key="setPaid">
          <ButtonLink
            onClick={() => onSetPaid(row.links.setPaid)}
            disabled={!row.links.setPaid}
          >
            Manuelt betalt
          </ButtonLink>
        </Menu.Item>,
        <Menu.Item key="sendReminder">
          <ButtonLink
            onClick={() => onSendReminder(row.links.sendReminder)}
            disabled={!row.links.sendReminder}
          >
            Send påmindelse til kunde
          </ButtonLink>
        </Menu.Item>,
        <Menu.Item key="sendInvoice">
          <ButtonLink
            onClick={() => onEmailInvoice(row.links.emailInvoice)}
            disabled={!row.links.emailInvoice}
          >
            Email faktura til kunde
          </ButtonLink>
        </Menu.Item>
      ];

      return (
        <Dropdown trigger={["click"]} overlay={() => <Menu>{items}</Menu>}>
          <ButtonLink icon="down-circle" />
        </Dropdown>
      );
    }
  }
];

const QuickPayOperationStatusLabel = ({
  status
}: {
  status: QuickPayOperationStatus;
}) => (
  <>
    <span style={{ display: "block" }}>
      {`${status.quickPayCode}: ${status.quickPayDescription}`}
    </span>
    <span style={{ display: "block" }}>
      {`${status.acquirerCode}: ${status.acquirerDescription}`}
    </span>
  </>
);
