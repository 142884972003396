import React from "react";
import { Row, Col } from "antd";

import Logo from "assets/img/nets-logo.png";

interface Props {
  accountName: string;
  accountNum: string;
  bankRegistrationNum: string;
  bankAccountNum: string;
}

export default ({ accountName, accountNum, bankRegistrationNum, bankAccountNum }: Props) =>
  <div className="payment-card">
    <Row type="flex" gutter={16}>
      <Col xs={12}>
        <img alt="Logo" src={Logo} className="payment-logo-nets" />
      </Col>
      <Col xs={12}>
        {/* <a href="#" className="payment-option">
              Skift aftale
              <Icon type="swap" theme="outlined" />
            </a> */}
      </Col>
    </Row>
    <h1 className="account-number">
      <span>
        <small className="account-number-label payment-label">Reg nr</small>
        {bankRegistrationNum}
      </span>
      <span>
        <small className="account-number-label payment-label">
          Konto nr
            </small>
        {bankAccountNum}
      </span>
    </h1>
    <Row type="flex" gutter={16}>
      <Col xs={12}>
        <small className="payment-label">Navn</small>
        <span>{accountName}</span>
      </Col>
      <Col xs={12}>
        <small className="payment-label">Konto nr</small>
        <span>{accountNum}</span>
      </Col>
    </Row>
  </div>;
