import React from "react";
import { Card } from "components";
import Logo from "assets/img/flexto-h-logo.png";
import "./index.less";
import Footer from "../footer";
import { flextoLessorFake } from "types/fakes";

class CardShell extends React.Component {
  render() {
    return (
      <div className="card-shell-container">
        <div className="row">
          <Card
            title={
              <div>
                <img className="logo" alt="Logo" src={Logo} />
              </div>
            }
          >
            {this.props.children}
          </Card>
          <Footer lessor={flextoLessorFake} />
        </div>
      </div>
    );
  }
}

export default CardShell;
