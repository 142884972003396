import { Checkbox, Form } from "@jbuschke/formik-antd";
import { Upload, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { UploadFile } from "antd/lib/upload/interface";
import { Button, ButtonLink, Date, Number, Spin } from "components";
import Table, { PartyCell } from "components/table";
import { Formik } from "formik";
import { paymentMethodTranslations } from "lib/intl";
import React, { useMemo, useState } from "react";
import { BillingBasisHeader, FileUpload } from "types/fxm";

interface Props {
  billingBasis: BillingBasisHeader[];
  loading?: boolean;
  onApprove: (values: Array<any>) => void;
  onDelete: (values: Array<any>) => void;
  onInvoicePreviewDownload: (header: BillingBasisHeader) => void;
  onSpecificationPreviewDownload: (header: BillingBasisHeader) => void;
  loadingPreview: boolean;
  requireSpecification: boolean;
}

interface FormValues {
  headers: {
    headerId: number;
    checked: boolean;
    attachments: FileUpload[];
  }[];
  action?: "approve" | "delete";
}

type HeaderAttachment = {
  headerId: number;
  attachments: UploadFile[];
};

export default (props: Props) => {
  const {
    billingBasis,
    onApprove,
    onDelete,
    onInvoicePreviewDownload,
    onSpecificationPreviewDownload,
    loading,
    loadingPreview,
    requireSpecification
  } = props;
  const [fileList, setFileList] = useState<HeaderAttachment[]>([]);

  const columns: ColumnProps<BillingBasisHeader>[] = [
    {
      dataIndex: "vehicleNum",
      key: "color",
      width: 1,
      render: (value: string, row: BillingBasisHeader) => ({
        props: {
          className:
            !requireSpecification || row.hasSpecification
              ? "status success"
              : "status warning",
          title:
            !requireSpecification || row.hasSpecification
              ? null
              : "Specifikation mangler"
        }
      }),
      onHeaderCell: () => ({
        className: "status"
      })
    },
    {
      title: "",
      dataIndex: "vehicleNum",
      key: "import",
      className: "fit",
      render: (value: string, record: BillingBasisHeader, index) => (
        <Checkbox name={`headers[${index}].checked`} />
      )
    },
    {
      title: "Køretøj",
      dataIndex: "",
      key: "vehicleNum",
      className: "fit center",
      render: (value: string, row: BillingBasisHeader) => (
        <div>
          <b>{row.vehicleNum}</b>
          <br />
          {row.vehicleModel}
          <br />
          {row.vehicleRegNum}
        </div>
      )
    },
    {
      title: "Faktura nr.",
      dataIndex: "invoiceNum",
      key: "invoiceNum",
      className: "right"
    },
    {
      title: "Faktura dato",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      className: "right",
      render: (v: string) => <Date value={v} />
    },
    {
      title: "Forfaldsdato",
      dataIndex: "dueDate",
      key: "dueDate",
      className: "right",
      render: (v: string) => <Date value={v} />
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      className: "right",
      render: (v: number, r: BillingBasisHeader) => (
        <div>
          <Number value={v} />
          <br />
          <span>{paymentMethodTranslations[r.customer.paymentMethod]}</span>
        </div>
      )
    },
    {
      title: "Kunde",
      dataIndex: "customer",
      key: "customer",
      render: PartyCell
    },
    {
      title: "Dokumenter",
      render: (_value: string, record: BillingBasisHeader, index) => (
        <AttachmentUpload
          headerId={record.id}
          fileList={fileList}
          setFileList={setFileList}
        />
      )
    },
    {
      title: "Download",
      key: "actions",
      className: "fit center",
      render: (v, r) => {
        return (
          <Spin spinning={loadingPreview}>
            {r.links.previewInvoice && (
              <ButtonLink onClick={() => onInvoicePreviewDownload(r)}>
                Faktura
              </ButtonLink>
            )}
            {r.links.previewSpecification && (
              <>
                <br />
                <ButtonLink onClick={() => onSpecificationPreviewDownload(r)}>
                  Specifikation
                </ButtonLink>
              </>
            )}
          </Spin>
        );
      }
    }
  ];

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        headers: billingBasis.map(x => ({
          headerId: x.id,
          checked: false,
          attachments: []
        }))
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { action } = values;
        const headersToApprove = values.headers.filter(x => x.checked);

        for (const h of headersToApprove) {
          if (!fileList.some(x => x.headerId === h.headerId)) continue;
          const files =
            fileList.find(x => x.headerId === h.headerId)!.attachments || [];
          for (const file of files) {
            const dataUrl = await toDataUrl(file as any);
            const base64 = dataUrl.split(",")[1];
            const mimeType = dataUrl.substring(
              dataUrl.indexOf(":") + 1,
              dataUrl.indexOf(";")
            );
            h.attachments = [
              ...(h.attachments || []),
              { name: file.name, mimeType, base64 }
            ];
          }
        }

        if (action === "approve") await onApprove(headersToApprove);
        else if (action === "delete") await onDelete(headersToApprove);

        setSubmitting(false);
      }}
      render={({
        handleSubmit,
        setFieldValue,
        isSubmitting,
        isValid,
        values
      }) => {
        const hasSelected = values.headers.find(x => x.checked);

        return (
          <Form>
            <div className="buttons">
              <Button
                type="default"
                onClick={() => {
                  billingBasis.forEach((h, i) => {
                    setFieldValue(`headers[${i}].checked`, !hasSelected);
                  });
                }}
              >
                {hasSelected ? "Fjern valgte" : "Vælg alle"}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  setFieldValue("action", "approve");
                  handleSubmit();
                }}
              >
                Godkend
              </Button>
              <Button
                htmlType="submit"
                type="danger"
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  setFieldValue("action", "delete");
                  handleSubmit();
                }}
              >
                Slet
              </Button>
            </div>
            <Table<BillingBasisHeader>
              rowKey="id"
              loading={loading}
              className="no-hover"
              dataSource={billingBasis}
              columns={columns}
              pagination={false}
            />
          </Form>
        );
      }}
    />
  );
};

const AttachmentUpload = ({
  headerId,
  fileList,
  setFileList
}: {
  headerId: number;
  fileList: HeaderAttachment[];
  setFileList: React.Dispatch<React.SetStateAction<HeaderAttachment[]>>;
}) => {
  let headerAttachment = fileList.find(x => x.headerId === headerId) || {
    headerId,
    attachments: []
  };

  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file: UploadFile) => {
        const isAllowedType = file.type === "application/pdf";
        if (!isAllowedType) {
          setFileList(state => [...state]);
          message.error(`${file.name} er ikke en PDF fil.`);
          return false;
        }

        const isDuplicate = !!headerAttachment.attachments.find(
          x => x.name === file.name
        );
        if (isDuplicate) {
          setFileList(state => [...state]);
          message.error(`${file.name} er allerede vedhæftet.`);
          return false;
        }

        headerAttachment.attachments = [...headerAttachment.attachments, file];
        setFileList(x => [
          ...x.filter(x => x.headerId !== headerId),
          headerAttachment
        ]);

        return false; // cancel upload
      },
      onRemove: (file: UploadFile) => {
        if (
          headerAttachment &&
          headerAttachment.attachments.some(x => x.uid === file.uid)
        ) {
          headerAttachment.attachments = [
            ...headerAttachment.attachments.filter(x => x.uid !== file.uid)
          ];
          setFileList(x => [
            ...x.filter(x => x.headerId !== headerId),
            headerAttachment
          ]);
          return true;
        }
        return false;
      }
    }),
    [headerId, setFileList, headerAttachment]
  );

  return (
    <Upload
      multiple
      {...uploadProps}
      fileList={headerAttachment.attachments}
      accept="application/pdf"
    >
      <Button icon="paper-clip">Vedhæft PDF</Button>
    </Upload>
  );
};

const toDataUrl = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
