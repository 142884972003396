import { Drawer as AntDrawer } from "antd";
import { DrawerProps as AntDrawerProps } from "antd/lib/drawer";
import { ButtonLink } from "components";
import React from "react";
import "./index.less";

export interface DrawerAction {
  icon: string;
  title: string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface DrawerProps extends AntDrawerProps {
  actions?: DrawerAction[];
  onClose?: () => void;
}

class Drawer extends React.Component<DrawerProps> {
  render() {
    let {
      actions = [],
      closable = true,
      onClose,
      title,
      children,
      ...rest
    } = this.props;

    let ab = [...actions];

    if (closable) {
      ab = [...ab, { icon: "close", title: "Luk", onClick: onClose }];
    }

    let t = (
      <>
        <span>{title}</span>
        <div className="actions">
          {ab.map((x, i) => (
            <ButtonLink
              key={i}
              icon={x.icon}
              disabled={x.disabled}
              onClick={x.onClick}
              title={x.title}
            />
          ))}
        </div>
      </>
    );

    return (
      <AntDrawer {...rest} title={t} closable={false} onClose={onClose}>
        {children}
      </AntDrawer>
    );
  }
}
export default Drawer;
