import { action, observable, makeObservable } from "mobx";
import { RootStore } from "stores";

const defaultDureation = 5;

interface Notification {
  type: "info" | "success" | "warning" | "error";
  message: string;
  description?: string;
  duration?: number;
}

class NotificationsStore {
  rootStore: RootStore;
  id: number = 0;

  notifications = new Map<number, Notification>();

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      notifications: observable,
      add: action,
      remove: action
    });

    this.rootStore = rootStore;
  }

  add(options: Notification) {
    let id = this.id++;
    this.notifications.set(id, options);

    if (options.duration) {
      setTimeout(() => this.remove(id), options.duration * 1000);
    }
  }

  remove(id: number) {
    this.notifications.delete(id);
  }

  info(
    message: string,
    description?: string,
    duration: number = defaultDureation
  ) {
    this.add({ type: "info", message, description, duration });
  }

  success(
    message: string,
    description?: string,
    duration: number = defaultDureation
  ) {
    this.add({ type: "success", message, description, duration });
  }

  warning(
    message: string,
    description?: string,
    duration: number = defaultDureation
  ) {
    this.add({ type: "warning", message, description, duration });
  }

  error(
    message: string,
    description?: string,
    duration: number = defaultDureation
  ) {
    this.add({ type: "error", message, description, duration });
  }
}

export default NotificationsStore;
