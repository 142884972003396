import React from "react";
import { RouterStore } from "stores";
import { connect, observer, ConnectedComponent } from "lib/mobx";
import { Router } from "router5";
import { Params } from "router5/types/types/base";

interface Stores {
  routerStore: RouterStore;
}

interface LinkState {
  active: boolean;
}

interface LinkProps {
  routeName: string;
  routeParams?: Params;
  title?: string;
  className?: string;
  activeClassName?: string;
  activeStrict?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onMouseOver?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

@connect("routerStore")
@observer
export default class Link extends ConnectedComponent<
  LinkProps,
  Stores,
  LinkState
> {
  static defaultProps = {
    activeClassName: "active",
    activeStrict: false,
    routeParams: {},
  };

  router: Router;

  constructor(props: LinkProps) {
    super(props);
    this.router = this.stores.routerStore.router;
    this.state = { active: this.isActive() };
  }

  isActive = () => {
    return this.router.isActive(
      this.props.routeName,
      this.props.routeParams,
      this.props.activeStrict
    );
  };

  clickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (this.props.onClick) {
      this.props.onClick(e);

      if (e.defaultPrevented) {
        return;
      }
    }

    const comboKey = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey;

    if (e.button === 0 && !comboKey) {
      e.preventDefault();
      this.stores.routerStore.navigate(
        this.props.routeName,
        this.props.routeParams
      );
    }
  };

  render() {
    const {
      routeName,
      routeParams,
      className,
      activeClassName,
      children,
      title,
      onMouseOver,
    } = this.props;

    const active = this.isActive();
    const href = this.stores.routerStore.buildUrl(routeName, routeParams);
    const linkclassName = (className ? className.split(" ") : [])
      .concat(active && activeClassName ? [activeClassName] : [])
      .join(" ");

    return (
      <a
        href={href}
        title={title}
        className={linkclassName}
        onClick={this.clickHandler}
        onMouseOver={onMouseOver}
      >
        {children}
      </a>
    );
  }
}
