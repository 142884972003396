import React from "react";
import { ConnectedComponent, connect, observer, toJS } from "lib/mobx";
import { RootStore } from "stores";
import { Page, Modal } from "components";
import Form from "./Form";
import ImportBillingBasisStore from "./store";
import "./index.less";
import { BillingBasisHeader } from "types/fxm";

interface Stores {
  rootStore: RootStore;
}

@connect("rootStore")
@observer
export default class ImportBillingBasisPage extends ConnectedComponent<
  any,
  Stores
> {
  constructor(props: any) {
    super(props);
    this.store = new ImportBillingBasisStore(this.stores.rootStore);
  }

  store: ImportBillingBasisStore;

  componentDidMount() {
    this.store.getBillingBasis();
  }

  handleApprove = async (values: Array<any>) => {
    let billingBasisJs = toJS(this.store.billingBasis);

    let canApprove =
      this.stores.rootStore.routerStore.lessor === "flexto" ||
      !values.find(
        x =>
          billingBasisJs.find(bb => bb.id === x.headerId)!.hasSpecification ===
          false
      );

    if (!canApprove) {
      Modal.error({
        title: "Rækkerne kan ikke godkendes",
        content:
          "Specifikationen skal være tilgængelig for alle de valgte rækker."
      });
    } else {
      await this.store.approve(values);
    }
  };

  handleDelete = async (values: object) => {
    let base = this;

    await Modal.confirm({
      title: "Er du sikker på, at du vil slette disse rækker?",
      content:
        "Rækkerne kan ikke genskabes, og bør kun bruges hvis der er fejl i det dannet faktureringsgrundlag.",
      async onOk() {
        await base.store.delete(values);
      },
      okText: "Slet",
      cancelText: "Annuller"
    });
  };

  handleInvoicePreviewDownload = async (header: BillingBasisHeader) => {
    this.store.downloadInvoicePreview(header);
  };

  handleSpecificationPreviewDownload = async (header: BillingBasisHeader) => {
    this.store.downloadSpecificationPreview(header);
  };

  render() {
    const {
      loading,
      billingBasis,
      loadingPreview,
      lessorNamespace
    } = this.store;

    return (
      <Page
        title="Fakturering af køretøjer"
        table
        className="import-billing-basis-page"
      >
        <Form
          billingBasis={toJS(billingBasis)}
          requireSpecification={lessorNamespace === "flextoSplit"}
          loading={loading}
          onApprove={this.handleApprove}
          onDelete={this.handleDelete}
          onInvoicePreviewDownload={this.handleInvoicePreviewDownload}
          onSpecificationPreviewDownload={
            this.handleSpecificationPreviewDownload
          }
          loadingPreview={loadingPreview}
        />
      </Page>
    );
  }
}
