import React from "react";
import "./index.less";
import moment from "moment";
import { Table as AntdTable } from "antd";
import {
  SorterResult,
  TableProps as AntdTableProps,
  ColumnProps
} from "antd/lib/table";
import { DynamicQueryParameters } from "lib/apiClient";
import { PaginationConfig } from "types/fxm";

export { default as TableActions } from "./TableActions";
export { default as PartyCell } from "./partyCell";
export { default as InputFilterDropdown } from "./filters/InputFilterDropdown";
export {
  default as InputNumberFilterDropdown
} from "./filters/InputNumberFilterDropdown";
export {
  default as DateRangeFilterDropdown
} from "./filters/DateRangeFilterDropdown";

export * from "antd/lib/table";

export interface FilterDropdownProps {
  setSelectedKeys: (selectedKeys: Array<any>) => void;
  selectedKeys: Array<any>;
  confirm: () => void;
  clearFilters: () => void;
}

interface Props<TRow> extends AntdTableProps<TRow> {
  onRequest?: (request: DynamicQueryParameters) => void;
  fontSize?: "small" | "medium" | "large";
  defaultDynamicQuery?: DynamicQueryParameters;
}

interface State<TRow> {
  filteredInfo?: Record<keyof TRow, string[]>;
  sortedInfo?: SorterResult<TRow>;
  defaultFilteredInfo?: Record<keyof TRow, string[]>;
  defaultSortedInfo?: SorterResult<TRow>;
}

class Table<TRow> extends React.Component<Props<TRow>, State<TRow>> {
  state: State<TRow> = {
    filteredInfo: undefined,
    sortedInfo: undefined,
    defaultFilteredInfo: undefined,
    defaultSortedInfo: undefined
  };

  constructor(props: Props<TRow>) {
    super(props);

    if (props.defaultDynamicQuery)
      this.setDefaultFilters(props.defaultDynamicQuery);
  }

  setDefaultFilters = async (dq: DynamicQueryParameters) => {
    let defaultFilteredInfo: Record<any, string[]> = {};
    let defaultSortedInfo: SorterResult<TRow> | undefined;

    if (dq.filters) {
      dq.filters.forEach(x => {
        defaultFilteredInfo[x.field] = x.values;
      });
    }

    this.state = { defaultFilteredInfo, defaultSortedInfo };
    // this.setState(s => ({
    //   ...s,
    //   defaultFilteredInfo,
    //   defaultSortedInfo
    // }));
  };

  convertToDynamicQuery = (
    pagination: PaginationConfig,
    filter: Record<keyof TRow, any[]>,
    sorter: SorterResult<TRow>
  ): DynamicQueryParameters => {
    let res: DynamicQueryParameters = {};

    if (pagination) {
      res.page = pagination.current;
      res.pageSize = pagination.pageSize;
    }

    if (sorter && sorter.field) {
      if (sorter.order === "ascend") {
        res.orderBy = "+" + sorter.field;
      } else {
        res.orderBy = "-" + sorter.field;
      }
    }

    res.filters = [];
    for (let key in filter) {
      if (filter[key].length > 0) {
        let isDateRange =
          filter[key].length === 2 &&
          filter[key].every(x => moment.isMoment(x));

        let filterValues = filter[key];
        if (isDateRange) {
          filterValues = [
            `${filter[key][0].format("YYYY-MM-DD")}..${filter[key][1].format(
              "YYYY-MM-DD"
            )}`
          ];
        } else {
          filterValues = filter[key].map(x => {
            if (moment.isMoment(x)) {
              return x.format("YYYY-MM-DD");
            } else {
              return x;
            }
          });
        }

        res.filters.push({
          field: key,
          values: filterValues
        });
      }
    }

    return res;
  };

  handleChange = (
    pagination: PaginationConfig,
    filters: Record<keyof TRow, string[]>,
    sorter: SorterResult<TRow>
  ) => {
    if (!this.props.onRequest) return;

    this.setState(x => ({
      ...x,
      filteredInfo: filters,
      sortedInfo: sorter
    }));

    const dynamicQuery = this.convertToDynamicQuery(
      pagination,
      filters,
      sorter
    );

    this.props.onRequest(dynamicQuery);
  };

  applySorterAndFilterToColumns = (columns?: ColumnProps<TRow>[]) => {
    if (!this.state || !columns || columns.length === 0) return;

    const { filteredInfo, sortedInfo } = this.state;
    const unTypedFilteredInfo = filteredInfo as Record<keyof any, string[]>;

    columns.forEach(col => {
      if (col.sorter) {
        col.sortOrder =
          sortedInfo && sortedInfo.columnKey === col.dataIndex
            ? sortedInfo.order
            : undefined;
      }

      if ((col.filters || col.filterDropdown) && col.dataIndex) {
        col.filteredValue = unTypedFilteredInfo
          ? unTypedFilteredInfo[col.dataIndex]
          : [];
      }
    });
  };

  resetSortAndFilters = () => {
    this.setState(x => ({
      ...x,
      filteredInfo: x.defaultFilteredInfo,
      sortedInfo: x.defaultSortedInfo
    }));
  };

  render() {
    let { onRequest, fontSize, ...rest } = this.props;

    if (!fontSize) fontSize = "medium";

    let className = `font-size-${fontSize}`;

    this.applySorterAndFilterToColumns(this.props.columns);

    return (
      <AntdTable<TRow>
        className={className}
        size="middle"
        onChange={this.handleChange}
        {...rest}
      />
    );
  }
}

export default Table;
