import React from "react";
import { paymentStateTranslations } from "lib/intl";
import { Tag } from "components";

const tagColor = (state: string) => {
  switch (state) {
    case "Paid":
      return "green";
    case "Cancelled":
    case "ChargedBack":
      return "orange";
    case "Approved":
      return "blue";
    default:
      return "lightgrey";
  }
};

export default ({
  state,
  tag,
  fallbackValue,
  isProcessing = false
}: {
  state: string;
  tag?: boolean;
  isProcessing?: boolean;
  fallbackValue?: string;
}) => {
  const text = isProcessing
    ? "Behandler..."
    : state
      ? paymentStateTranslations[state]
      : fallbackValue || "Manuelt";
  return tag ? <Tag color={tagColor(state)}>{text}</Tag> : <span>{text}</span>;
};
