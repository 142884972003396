import React from "react";
import { InputNumber, ButtonLink } from "components";
import { FilterDropdownProps } from "..";

interface Props extends FilterDropdownProps {
  placeholder?: string;
  width?: number;
  precision?: number;
}

const InputNumberFilterDropdown = ({
  placeholder,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  width = 100,
  precision
}: Props) => (
  <div style={{ padding: 8 }}>
    <InputNumber
      placeholder={placeholder}
      value={selectedKeys[0]}
      onChange={v => setSelectedKeys(v ? [v] : [])}
      onPressEnter={confirm}
      style={{ width }}
      precision={precision}
    />
    <div style={{ display: "inline", paddingLeft: 7, paddingRight: 7 }}>
      <ButtonLink icon="search-o" onClick={confirm} />
    </div>
    <div style={{ display: "inline", paddingLeft: 7, paddingRight: 7 }}>
      <ButtonLink icon="close-o" onClick={clearFilters} />
    </div>
  </div>
);

export default InputNumberFilterDropdown;
