import React, { Component } from "react";
import { Icon } from "components";
import "./index.less";

interface ButtonLinkProps {
  onClick?: () => void;
  title?: string;
  icon?: string;
  disabled?: boolean;
}

class ButtonLink extends Component<ButtonLinkProps> {
  handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (this.props.onClick) this.props.onClick();
  };

  render() {
    const { icon, children, title, disabled } = this.props;

    return (
      <button
        className="button-link"
        title={title}
        onClick={this.handleClick}
        disabled={disabled}
      >
        {icon ? <Icon type={icon} /> : children}
      </button>
    );
  }
}

export default ButtonLink;
