import React from "react";
import CardShell from "components/layout/cardShell";

const ErrorPage = () => (
  <CardShell>
    <p>Der skete en kommunikations-fejl med serveren.</p>
    <p>Prøv venligst igen senere.</p>
    <p>Hvis problemet fortsætter, så kontakt Flexto.</p>
    <a href="/">Tryk her for at prøve igen.</a>
  </CardShell>
);

export default ErrorPage;
