import { ButtonLink, Drawer, Loader, Modal } from "components";
import { DrawerAction } from "components/drawer";
import Table from "components/table";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Invoice, LinkModel } from "types/fxm";
import "./index.less";
import { Store } from "./store";

interface Props {
  visible?: boolean;
  invoiceLink?: LinkModel;
  actions?: DrawerAction[];
  onClose?: () => void;
}

const InvoiceDrawer = observer(
  ({ invoiceLink, visible = false, onClose, actions = [] }: Props) => {
    const { current: store } = useRef(new Store());
    const { loading, invoice, html, downloadInvoice } = store;
    const [showAttachments, setShowAttachments] = useState(false);

    useEffect(() => {
      if (invoiceLink) store.getInvoice(invoiceLink);
    }, [invoiceLink, store]);

    let title =
      invoice &&
      `${invoice.isCreditNote ? "Kreditnota" : "Faktura"} ${
        invoice.invoiceNum
      }`;

    return (
      <Drawer
        visible={visible}
        title={title}
        width={800}
        className="invoice-preview-drawer"
        onClose={onClose}
        actions={[
          {
            icon: "download",
            title: "Download PDF faktura/kreditnota",
            disabled: !invoice,
            onClick: () => downloadInvoice(invoice!)
          },
          {
            icon: "paper-clip",
            title:
              invoice && invoice.hasAttachments
                ? "Vis vedhæftede dokumenter"
                : "Ingen vedhæftede dokumenter",
            disabled: !(invoice && invoice.hasAttachments),
            onClick: () => setShowAttachments(true)
          },
          ...actions
        ]}
      >
        <div className="invoice-preview-drawer-body">
          {loading ? (
            <Loader center text="Henter faktura..." />
          ) : (
            <>
              <AttachmentModal
                store={store}
                invoice={invoice!}
                visible={showAttachments}
                onClose={() => setShowAttachments(false)}
              />
              <iframe src={html} title="Invoice preview" />
            </>
          )}
        </div>
      </Drawer>
    );
  }
);

const AttachmentModal = observer(
  ({
    store: { getAttachments, downloadAttachment, attachments },
    invoice,
    visible = false,
    onClose
  }: {
    store: Store;
    invoice: Invoice;
    visible: boolean;
    onClose: () => void;
  }) => {
    useEffect(() => {
      if (visible) getAttachments(invoice);
    }, [visible, invoice, getAttachments]);

    return (
      <Modal
        className="modal-table"
        title="Vedhæftninger"
        visible={visible}
        onCancel={onClose}
        footer={null}
      >
        <Table
          rowKey="id"
          dataSource={attachments}
          loading={!attachments}
          pagination={false}
          columns={[
            {
              dataIndex: "type",
              title: "Type",
              render: v => (v === "Specification" ? "Specifikation" : "Andet")
            },
            {
              dataIndex: "fileName",
              title: "Filnavn"
            },
            {
              render: (v, a) => (
                <DownloadButton onDownload={() => downloadAttachment(a)} />
              )
            }
          ]}
        />
      </Modal>
    );
  }
);

const DownloadButton = ({
  onDownload
}: {
  onDownload: () => Promise<void>;
}) => {
  const [isDownloading, setDownloading] = useState(false);

  const handleClick = () => {
    setDownloading(true);

    onDownload().then(() => {
      setDownloading(false);
    });
  };

  return (
    <ButtonLink
      icon="download"
      onClick={handleClick}
      disabled={isDownloading}
    />
  );
};

export default InvoiceDrawer;
