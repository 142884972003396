import React from "react";
import { Page, Row, Col } from "components";
import PartyDetails from "./PartyDetails";
import PaymentMeans from "./PaymentMeans";
import { ConnectedComponent, connect, observer, toJS } from "lib/mobx";
import { RouterStore, RootStore } from "stores";
import LesseePageStore from "./store";
import { Tabs } from "antd";
import LesseeSelector from "./lesseeSelector";
import StatementsTab from "./statementsTab";
import EmailsTab from "./emailsTab";
import "./index.less";
import { RouteName } from "sitemap";

interface Stores {
  rootStore: RootStore;
  routerStore: RouterStore;
}

@connect(
  "routerStore",
  "rootStore"
)
@observer
class LesseePage extends ConnectedComponent<any, Stores> {
  constructor(props: any) {
    super(props);
    this.store = new LesseePageStore(this.stores.rootStore);
  }

  store: LesseePageStore;

  async componentDidMount() {
    let lesseeId = this.stores.routerStore.params.id;
    this.store.init(lesseeId);
  }

  searchLesseeTypeahead = (value: string) => {
    this.store.getLesseesTypeahead(value);
  }

  selectLessee = (lesseeId: number) => {
    this.store.clearLesseesTypeahead();
    this.stores.routerStore.navigate(RouteName.Lessee, { id: lesseeId });
  }

  render() {
    const { party, paymentMeans, loading, customerTypeahead } = this.store;

    if (loading) {
      return <Page loading />;
    }

    if (!party) return null;

    return (
      <div>
        <Page
          title={`${party.customerNum} - ${party.name}`}
          icon={party.role === "Private" ? "user" : "bank"}
          actions={
            <LesseeSelector
              dataSource={toJS(customerTypeahead)}
              onSearch={this.searchLesseeTypeahead}
              onSelect={this.selectLessee}
            />
          }
        >
          <Row type="flex" justify="space-between">
            <Col>
              <h3>Stamdata</h3>
              <PartyDetails party={party} />
            </Col>
            {paymentMeans && (
              <Col>
                <PaymentMeans
                  party={party}
                  paymentMeans={paymentMeans}
                  store={this.store}
                />
              </Col>
            )}
          </Row>
        </Page>
        <Page subPage table tabs>
          <Tabs type="card">
            <Tabs.TabPane tab="Kontoudtog" key="kontoudtog">
              <StatementsTab pageStore={this.store} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Emails" key="emails">
              <EmailsTab pageStore={this.store} />
            </Tabs.TabPane>
          </Tabs>
        </Page>
      </div>
    );
  }
}

export default LesseePage;
