import React from "react";
import { ButtonLink, Date, Modal } from "components";
import { PaymentOption } from "components/payments";
import {
  LeverandorServiceSubscription,
  Party,
  PartyPaymentMeans,
  QuickPaySubscription
} from "types/fxm";
import LesseePageStore from "./store";

interface Props {
  party: Party;
  paymentMeans: PartyPaymentMeans;
  store: LesseePageStore;
}

function reset(paymentMeans: PartyPaymentMeans, store: LesseePageStore) {
  if (paymentMeans.hasPendingPayment) {
    return Modal.error({
      title: "Aktiv betaling",
      content:
        "Bealingsmetoden kan ikke nulstilles fordi der er en betaling igang. Udlign betalingen først, eller afvent den automatisk bliver udlignet."
    });
  }

  Modal.confirm({
    title: "Nulstil betalingsmetode",
    content:
      "Er du sikker på at du vil nulstille brugerens betalingsmetode? Den nuværende betalingsmetode vil blive opsagt og kunden vil overgå til FI-kort betaling.",
    okText: "Nulstil",
    cancelText: "Annuller",
    onOk: () => store.resetPaymentMeans(paymentMeans)
  });
}

export default ({ party, paymentMeans, store }: Props) => {
  const {
    currentPaymentMethod: pm,
    quickPaySubscription: qps,
    leverandorServiceSubscription: lss
  } = paymentMeans;

  let content = null;

  if (qps) {
    content = <QuickPayView party={party} qps={qps} />;
  } else if (lss) {
    content = <LeverandorServiceView party={party} lss={lss} />;
  } else if (pm === "PBS") {
    content = (
      <section>
        <p>Tilmeldt Betalingsservice</p>
      </section>
    );
  } else if (pm === "FIK") {
    content = (
      <p>
        FI-Kort
        <br />
        Kunden er ikke tilmeldt automatisk betaling
      </p>
    );
  } else {
    content = (
      <p>
        <i>Ugyldig betalingstilstand.</i>
      </p>
    );
  }

  return (
    <React.Fragment>
      <h3>
        Betalingsoplysninger{" "}
        {paymentMeans && paymentMeans.links["reset"] && (
          <ButtonLink
            icon="disconnect"
            title="Nulstil betalingsmetode"
            disabled={store.resettingPaymentMeans}
            onClick={() => reset(paymentMeans, store)}
          />
        )}
      </h3>
      {content}
    </React.Fragment>
  );
};

const QuickPayView = ({
  party,
  qps
}: {
  party: Party;
  qps: QuickPaySubscription;
}) => {
  if (qps.state === "Initiated") {
    return (
      <p>
        FI-Kort
        <br />
        Afventer kundens tilmelding af betalingskort.
      </p>
    );
  }

  return (
    <section>
      <p>
        Tilmeldt kort-abonnement siden d. <Date value={qps.authorizedDate} />
      </p>
      <PaymentOption
        customerNum={party.customerNum}
        payeeName={party.name}
        paymentCard={qps.paymentCard}
      />
    </section>
  );
};

const LeverandorServiceView = ({
  party,
  lss
}: {
  party: Party;
  lss: LeverandorServiceSubscription;
}) => {
  let msg = "";
  if (lss.state === "Initiated") {
    return (
      <section>
        <p>
          FI-Kort
          <br />
          Afventer kundens tilmelding til LeverandørService
        </p>
      </section>
    );
  } else if (lss.state === "Registered")
    msg = "Tilmeldt automatisk betaling via LeverandørService.";
  else if (lss.state === "AwaitingRegistration")
    msg = "Kunde tilmeldt LeverandørService - afventer bekræftelse.";
  else if (lss.state === "AwaitingCancellation") {
    msg = "Afventer afmelding hos LeverandørService.";
  } else msg = lss.state;
  return (
    <section>
      <p>{msg}</p>
      <PaymentOption
        customerNum={party.customerNum}
        payeeName={party.name}
        leverandorServiceSubscription={lss}
      />
    </section>
  );
};
