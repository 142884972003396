import React from "react";
import "./index.less";
import { Party } from "types/fxm";
import { BBAN } from "components";

export default ({
  customerNum,
  name,
  cvr,
  email,
  bban,
  address: { line1, line2, cityName, postalCode },
}: Party) => {
  return (
    <div className="party-cell">
      {customerNum && <b>{customerNum}</b>}
      <address>
        {name} <br />
        {line1} <br />
        {line2 && (
          <span>
            {line2}
            <br />
          </span>
        )}
        {postalCode} {cityName}
      </address>
      {cvr && <span>CVR: {cvr}</span>}
      {email && (
        <span>
          <br />
          E-mail: {email}
        </span>
      )}
      {bban && (
        <span>
          <br />
          Bankkonto: <BBAN value={bban} />
        </span>
      )}
    </div>
  );
};
