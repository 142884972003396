import { Lessor } from "./fxm";

export const flextoLessorFake: Lessor = {
  id: 0,
  namespace: "flexto",
  party: {
    name: "FLEXTO a/s",
    externalId: "n/a",
    role: "n/a",
    customerNum: "0",
    cvr: "33372671",
    telephone: "96365225",
    email: "bogholderi@flexto.dk",
    paymentMethod: "FIK",
    address: {
      line1: "Porsborgparken 39",
      postalCode: "9530",
      cityName: "Støvring"
    },
    links: {}
  }
};

export const flextoSplitLessorFake: Lessor = {
  id: 0,
  namespace: "flextoSplit",
  party: {
    name: "FLEXTO SPLITLEASING ApS",
    externalId: "n/a",
    role: "n/a",
    customerNum: "0",
    cvr: "31249759",
    telephone: "96365225",
    email: "split-bogholderi@flexto.dk",
    paymentMethod: "FIK",
    address: {
      line1: "Porsborgparken 39",
      postalCode: "9530",
      cityName: "Støvring"
    },
    links: {}
  }
};
