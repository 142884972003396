import React from "react";
import { Input, Button, Icon } from "components";

import "./TableActions.less";

const TableActions = (props: {
  allowSearch?: boolean;
  searchWidth?: number;
  onSearch?: (value: string) => void;
  onSearchChanged?: (value: string) => void;
  searchValue?: string;
  searchPlaceholder?: string;
  onClearFilters: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <div className="table-actions">
      {props.children}
      {props.allowSearch && (
        <div>
          <Input
            style={{ width: props.searchWidth || 250 }}
            placeholder={props.searchPlaceholder}
            value={props.searchValue}
            onChange={e =>
              props.onSearchChanged && props.onSearchChanged(e.target.value)
            }
            onPressEnter={e =>
              props.onSearch && props.onSearch(e.currentTarget.value)
            }
            prefix={<Icon type="search" />}
          />
        </div>
      )}
      <div>
        <Button
          icon="sync"
          title="Opdater og nulstil filtre og sorteringer"
          onClick={() => props.onClearFilters()}
        />
      </div>
    </div>
  );
};

export default TableActions;
