import React from "react";
import CardShell from "components/layout/cardShell";

const ForbiddenPage = () => (
  <CardShell>
    <span>Du har ikke adgang til at se den forespurgte side.</span>
  </CardShell>
);

export default ForbiddenPage;
