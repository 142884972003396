import { RootResponse, PaginationConfig, LinkModel } from "types/fxm";
import { UserStore } from "stores";

export interface ApiResponse<T> {
  payload?: T;
  pagination?: PaginationConfig;
  blob?: Blob;
}

export interface DynamicQueryParameters {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  filters?: DynamicQueryFilter[];
}

export interface DynamicQueryFilter {
  field: string;
  operator?: string;
  values: string[];
}

interface ApiOptions {
  method?: string;
  body?: any;
  queryParams?: object;
  query?: DynamicQueryParameters;
}

declare var process: {
  env: {
    REACT_APP_FXM_API: string;
  };
};

class RequestError extends Error {
  formErrors: any;

  constructor(statusText: string, formErrors: any) {
    super(statusText);
    this.formErrors = formErrors;
  }
}

export default class ApiClient {
  constructor(userStore: UserStore) {
    this.userStore = userStore;
  }

  userStore: UserStore;
  baseUrl = process.env.REACT_APP_FXM_API;

  getRoot = (): Promise<ApiResponse<RootResponse>> => {
    return this.request("/api");
  };

  requestLink<T>(
    link: LinkModel,
    options: ApiOptions = {},
    parameterPlaceholders?: Record<string, string | number>
  ): Promise<ApiResponse<T>> {
    if (!link) throw new Error("Link not found.");
    options.method = link.method;

    return this.request(link.href, options, parameterPlaceholders);
  }

  requestUserLink<T>(
    linkName: string,
    options: ApiOptions = {},
    parameterPlaceholders?: Record<string, string | number>
  ): Promise<ApiResponse<T>> {
    const link = this.userStore.resources[linkName];
    if (!link) throw new Error("Link not found.");
    options.method = link.method;
    return this.request(link.href, options, parameterPlaceholders);
  }

  async request<T>(
    path: string,
    options?: ApiOptions,
    parameterPlaceholders?: Record<string, string | number>
  ): Promise<ApiResponse<T>> {
    let url = path.startsWith(this.baseUrl) ? path : this.baseUrl + path;

    let headers = new Headers();

    if (this.userStore.isAuthenticated)
      headers.append("Authorization", `Bearer ${this.userStore.accessToken}`);

    let requestOptions: RequestInit = {
      ...options,
      headers,
    };

    if (options && options.body) {
      requestOptions.body = JSON.stringify(options.body);
    }

    if (requestOptions.method === "POST" || requestOptions.method === "PUT") {
      headers.append("Content-Type", "application/json");
    }

    let queryParams = [];

    if (options && options.query) {
      const { filters, ...qp } = options.query;
      queryParams.push(this.serializeQueryParams(qp));
      if (filters) {
        queryParams.push(
          filters
            .map((x) => `${x.field}${x.operator || "="}${x.values.join(",")}`)
            .join("&")
        );
      }
    }

    if (options && options.queryParams) {
      queryParams.push(this.serializeQueryParams(options.queryParams));
    }

    queryParams = queryParams.filter((x) => !!x);

    if (queryParams.length > 0) url = `${url}?${queryParams.join("&")}`;

    if (parameterPlaceholders) {
      for (const key in parameterPlaceholders) {
        url = url.replace(`$${key}`, parameterPlaceholders[key].toString());
      }
    }

    const response = await fetch(url, requestOptions);

    if (response.ok) {
      return await this.parseSuccess(response);
    } else {
      return await this.parseError(response);
    }
  }

  private async parseSuccess<T>(response: Response): Promise<ApiResponse<T>> {
    if (!this.isJsonResponse(response)) {
      return { blob: await response.blob() };
    }

    let json = await response.json();

    this.convertResponseLinksToHashMap(json);
    const pagination = this.parsePaginationResponse(response);

    return {
      payload: json,
      pagination,
    };
  }

  private async parseError<T>(response: Response): Promise<ApiResponse<T>> {
    let json;
    if (this.isJsonResponse(response)) {
      json = await response.json();
      if (response.status === 400) {
        // TODO: Determine when it's actual form errors, or generic errors.
        // let formErrors = this.convertModelErrorsToFormikErrors<T>(json);
        // throw new RequestError(response.statusText, formErrors);
        throw new RequestError(response.statusText, json);
      }
    }

    throw new Error(response.statusText);
  }

  private isJsonResponse = (response: Response): boolean => {
    const contentType = response.headers.get("content-type");
    return !!(contentType && contentType.indexOf("application/json") !== -1);
  };

  // private convertModelErrorsToFormikErrors<T>(obj: any): FormikErrors<T> {
  //   const e: { [index: string]: string } = {};

  //   Object.keys(obj).forEach(key => {
  //     var index = camelize(key);
  //     var val = obj[key][0];
  //     e[index] = val;
  //   });

  //   dotify(e);

  //   return e as FormikErrors<T>;
  // }

  private convertResponseLinksToHashMap = (obj: any) => {
    if (!obj) return;
    if (Array.isArray(obj)) {
      obj.forEach(this.convertResponseLinksToHashMap);
    } else {
      for (let i of Object.keys(obj)) {
        if (i === "links") {
          const links: { [index: string]: any } = {};
          obj[i].forEach((link: { rel: string; rest: any }) => {
            const { rel, ...rest } = link;
            links[rel] = rest;
          });
          obj.links = links;
        } else if (obj[i] !== null && typeof obj[i] === "object") {
          this.convertResponseLinksToHashMap(obj[i]);
        }
      }
    }
  };

  private parsePaginationResponse(response: any) {
    const totalCount = response.headers.get("X-Pagination-TotalCount");
    if (totalCount) {
      const pageSize = response.headers.get("X-Pagination-PageSize");
      const pageIndex = response.headers.get("X-Pagination-PageIndex");

      // Build antd pagination settings object and include it in the response object.
      return {
        hideOnSinglePage: true,
        total: parseInt(totalCount),
        current: parseInt(pageIndex),
        pageSize: parseInt(pageSize),
      } as PaginationConfig;
    }
  }

  private serializeQueryParams(qp: any, prefix?: string) {
    if (!qp) return undefined;

    const str = new Array<string>();
    for (const p in qp) {
      if (qp.hasOwnProperty(p)) {
        const k = prefix ? `${prefix}[${p}]` : p;
        let v = qp[p];

        if (v) {
          let s =
            typeof v === "object"
              ? this.serializeQueryParams(v, k)
              : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;

          if (s) str.push(s);
        }
      }
    }

    return str.length > 0 ? str.join("&") : undefined;
  }
}
