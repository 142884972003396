import { Popover } from "antd";
import { Date, Icon } from "components";
import Table, { ColumnProps } from "components/table";
import { DynamicQueryParameters } from "lib/apiClient";
import { observer, toJS } from "lib/mobx";
import React from "react";
import { OutboundMessage } from "types/fxm";
import LesseePageStore from "../store";
import "./index.less";

interface Props {
  pageStore: LesseePageStore;
}

interface State {
  selectedRowKeys: string[] | number[] | undefined;
}

@observer
export default class EmailsTab extends React.Component<Props, State> {
  state: Readonly<State> = {
    selectedRowKeys: []
  };

  iframeRef = React.createRef<HTMLIFrameElement>();

  componentDidMount() {
    this.handleTableRequest();
  }

  handleTableRequest = async (query?: DynamicQueryParameters) => {
    await this.props.pageStore.getOutboundMessages(query);

    if (this.props.pageStore.outboundMessages.length > 0)
      this.handleSelectChange([this.props.pageStore.outboundMessages[0].id]);
  };

  handleSelectChange = (selectedRowKeys: string[] | number[] | undefined) => {
    let existing = this.state.selectedRowKeys as number[];

    if (selectedRowKeys && this.state.selectedRowKeys) {
      let sr = selectedRowKeys[0] as number;
      if (existing.includes(sr)) {
        return;
      }
    }

    this.setState({ selectedRowKeys });

    if (selectedRowKeys) {
      let om = this.props.pageStore.outboundMessages.find(
        x => x.id === (selectedRowKeys[0] as number)
      );

      if (
        om &&
        this.iframeRef.current &&
        this.iframeRef.current.contentDocument
      )
        this.iframeRef.current.contentDocument.body.innerHTML = om.htmlBody;
    }
  };

  render() {
    const {
      outboundMessages,
      outboundMessagesPagination,
      loadingOutboundMessages
    } = this.props.pageStore;

    const { selectedRowKeys } = this.state;

    const columns: ColumnProps<OutboundMessage>[] = [
      {
        dataIndex: "receivedAt",
        title: "Sendt d.",
        className: "fit",
        sorter: true,
        render: (v: string) => <Date value={v} time />
      },
      {
        dataIndex: "status",
        title: "Status",
        className: "fit",
        sorter: true
      },
      {
        dataIndex: "subject",
        title: "Emne",
        sorter: true
      },
      {
        render: (v, msg) =>
          msg.attachmentFileNames && (
            <Popover
              placement="right"
              content={msg.attachmentFileNames.split(",").map(fileName => (
                <span style={{ display: "block" }}>{fileName}</span>
              ))}
            >
              <Icon type="paper-clip" />
            </Popover>
          )
      }
    ];

    return (
      <div className="emails-tab">
        <div className="left">
          <Table<OutboundMessage>
            className="emails-tab-table clickable-row"
            rowKey="id"
            style={{ width: 600 }}
            size="middle"
            loading={loadingOutboundMessages}
            dataSource={toJS(outboundMessages)}
            columns={columns}
            pagination={outboundMessagesPagination}
            onRequest={this.handleTableRequest}
            rowSelection={{
              onChange: this.handleSelectChange,
              selectedRowKeys: selectedRowKeys
            }}
            onRow={record => ({
              onClick: () => {
                this.handleSelectChange([record.id]);
              }
            })}
          />
        </div>
        <div className="right">
          <iframe
            ref={this.iframeRef}
            frameBorder="0"
            scrolling="yes"
            title="Email"
          />
        </div>
      </div>
    );
  }
}
