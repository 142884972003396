import React from "react";
import { PaymentShell } from "components/layout";
import { Button } from "components";
import { Lessor } from "types/fxm";

interface Props {
  onClose: () => void;
  lessor: Lessor;
}

export default (props: Props) => (
  <PaymentShell lessor={props.lessor}>
    <h3>Handelsbetingelser for betalingsaftale med FLEXTO splitleasing ApS</h3>
    <p>
      <b>Betaling af fakturaer</b>
      <br />
      FLEXTO splitleasing ApS modtager betaling med VISA-kort eller via
      LeverandørService. Betalingerne opkræves på forfaldsdagen, som fremgår af
      fakturaen. Faktura udsendes efter de i splitleasingaftalen aftalte vilkår
      og fremsendes på den aftalte e-mail.
    </p>
    <p>Alle beløb er i danske kroner (DKR) og er inkl. moms.</p>
    <p>
      FLEXTO splitleasing ApS bruger en godkendt betalingsserver, der sikrer
      opbevaring af betalings-oplysninger. Denne er kompatibel med PCI DSS Level
      1 standarden. Al kommunikation til/fra betalingsserveren er TLS krypteret.
    </p>
    <p>
      <b>Leveringsbetingelser</b>
      <br />
      Ydelsen er leveret ved afregning af fakturaen. Ydelserne er aftalt i den
      tilhørende splitleasingaftale.
    </p>
    <p>
      <b>Fortrydelsesret og opsigelse</b>
      <br />
      Abonnementsaftalen kan fortrydes og opsiges og betalinger overgår til
      manuel håndtering, hvorfor der opkræves et gebyr på kr. 50 DKR pr.
      betaling.
    </p>
    <p>
      <b>Nyt kort (VISA-kort)</b>
      <br />
      Du modtager automatisk besked, såfremt dit kort udløber, hvorved du har
      mulighed for at tilknytte et nyt kort.
    </p>
    <p>
      <b>Nyt kontonummer (LeverandørService)</b>
      <br />
      Hvis du ønsker at ændre betalingsaftalen til en anden konto, giver du
      FLEXTO splitleasing ApS besked på e-mail{" "}
      <a href="mailto:split-bogholderi@flexto.dk">split-bogholderi@flexto.dk</a>
      .
      <br />
      Herefter du bliver bedt om at indtaste de nye kontooplysninger, næste gang
      der kommer en betaling.
    </p>
    <p>
      <b>Refusion</b>
      <br />
      Hvis der er aftalt refusion, så bedes du kontakte selskabet med
      kontooplysinger, så vi kan overføre det aftalte beløb.
    </p>
    <Button
      onClick={props.onClose}
      block
      style={{ marginBottom: 20 }}
      htmlType="button"
    >
      Tilbage til betaling
    </Button>
  </PaymentShell>
);
