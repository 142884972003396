import React from "react";

export default ({ value }: { value: string }) => {
  if (!value) return null;

  let regNum = value.substr(0, 4);
  // Remove leading zeroes.
  let accountNum = value.substr(4, value.length).replace(/^0+/, "");

  return <span>{`${regNum}-${accountNum}`}</span>;
};
