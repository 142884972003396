import React from "react";
import { ConnectedComponent, connect, observer } from "lib/mobx";
import { currency } from "lib/intl";
import { Button, ButtonLink } from "components";
import { PaymentShell } from "components/layout";
import { RouterStore } from "stores";
import { PublicPayment } from "types/fxm";
import FlextoTermsCC from "../FlextoTermsCC";
import FlextoSplitTerms from "../FlextoSplitTerms";

interface Props {
  payment: PublicPayment;
}

interface Stores {
  routerStore: RouterStore;
}

interface State {
  showTerms: boolean;
  redirecting: boolean;
}

@connect("routerStore")
@observer
class AuthorizeQuickPaySubscription extends ConnectedComponent<
  Props,
  Stores,
  State
> {
  state: Readonly<State> = {
    showTerms: false,
    redirecting: false
  };

  togglTerms = () => {
    this.setState(s => ({ ...s, showTerms: !s.showTerms }));
  };

  redirectToGateway = () => {
    const { payment } = this.props;
    this.setState(s => ({ ...s, redirecting: true }));

    // Save payment in session storage so we can retrieve it when returned from the payment provider.
    window.sessionStorage.setItem("payment", JSON.stringify(payment));

    // Redirect to payment provider.
    window.location.href = payment.quickPayPaymentLink;
  };

  render() {
    const { payment } = this.props;
    const { showTerms, redirecting } = this.state;

    if (payment.isPaid || payment.isSubscribed) {
      return (
        <PaymentShell lessor={payment.lessor}>
          <h3>Kære {payment.customerName}</h3>
          <p>
            Vi har registreret dit betalingskort og du vil automatisk blive
            oprævet fremover.
          </p>
        </PaymentShell>
      );
    }

    if (showTerms) {
      if (payment.lessor.namespace === "flextoSplit")
        return (
          <FlextoSplitTerms onClose={this.togglTerms} lessor={payment.lessor} />
        );
      else
        return (
          <FlextoTermsCC onClose={this.togglTerms} lessor={payment.lessor} />
        );
    }

    return (
      <PaymentShell lessor={payment.lessor}>
        <h3>Kære {payment.customerName}</h3>

        <p>Velkommen til vores betalingsportal.</p>

        <p>
          Du kan her {payment.isCreditNote ? "få udbetalt" : "betale"} din
          tilsendte {payment.isCreditNote ? "kreditnota" : "faktura"}{" "}
          <b>{payment.invoiceNum}</b> pålydende{" "}
          <b>kr. {currency(payment.amount)}</b> og samtidig tilmelde dit
          betalingskort til fremtidige afregninger. Herefter vil vi hver måned
          hæve/udbetale den månedlige afregning jf. fremsendt
          faktura/kreditnota.
        </p>

        <p>
          Vi opbevarer <b>ikke</b> dine kort-oplysninger og al kommunikation er
          kryptereret.
        </p>

        <p>
          Ved tryk på "Tilmeld betalingskort", accepterer du vores
          handelsbetingelser og sendes videre til betalingsudbyderen hvor du
          kan registrere dit betalingskort.{" "}
          <ButtonLink onClick={this.togglTerms}>
            Du kan læse vores handelsbetingelser her.
          </ButtonLink>
        </p>

        <Button type="primary" loading={redirecting} onClick={this.redirectToGateway}>Tilmeld betalingskort</Button>

        {/* <QuickPayPaymentForm
          paymentLink={payment.quickPayPaymentLink}
          onPaymentCompleted={this.handlePaymentCompleted}
        /> */}
      </PaymentShell>
    );
  }
}

export default AuthorizeQuickPaySubscription;
