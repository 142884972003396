import { action, makeObservable, observable } from "mobx";
import { Router, State } from "router5";
import { CancelFn, DoneFn, Params } from "router5/types/types/base";
import { AppRoute, RouteName, getAppRoute } from "sitemap";
import { IRootStore } from "stores";
import { LessorNamespace } from "types/fxm";

class RouterStore {
  rootStore: IRootStore;
  router: Router;
  currentState?: State;
  fromState?: State;
  currentAppRoute?: AppRoute;
  lessor: LessorNamespace = "flexto";

  constructor(rootStore: IRootStore, router: Router) {
    makeObservable(this, {
      currentState: observable,
      fromState: observable,
      currentAppRoute: observable,
      lessor: observable,
      setCurrent: action,
      setFrom: action
    });

    this.rootStore = rootStore;
    this.router = router;
  }

  setCurrent(state: State) {
    this.lessor =
      (state.params.lessor === "flextosplit"
        ? "flextoSplit"
        : state.params.lessor) || this.lessor;

    this.currentState = state;
    this.currentAppRoute = getAppRoute(state.name);
  }

  setFrom(state: State) {
    this.fromState = state;
  }

  get params() {
    if (!this.currentState) return {} as Record<string, any>;

    return this.currentState.params;
  }

  buildUrl(routeName: string, routeParams?: Params) {
    let params = { lessor: this.lessor, ...routeParams };

    if (this.router.buildUrl) {
      return this.router.buildUrl(routeName, params);
    }

    return this.router.buildPath(routeName, params);
  }

  navigate(
    routeName: RouteName | string,
    routeParams?: Record<string, any>,
    done?: DoneFn
  ): CancelFn {
    let params = { lessor: this.lessor, ...routeParams };
    return this.router.navigate(routeName, params, done);
  }
}
export default RouterStore;
