import React from "react";
import Footer from "../footer";
import "./index.less";
import FlextoLogo from "assets/img/flexto-h-logo.png";
import FlextoSplitLogo from "assets/img/flexto_split_logo.png";
import { Lessor } from "types/fxm";

const PaymentShell = ({
  children,
  lessor
}: {
  lessor: Lessor;
  children: React.ReactNode;
}) => {
  return (
    <div className="page-wrapper">
      <div>
        <div className="container-wrapper">
          <img
            alt="Logo"
            src={
              lessor.namespace === "flextoSplit" ? FlextoSplitLogo : FlextoLogo
            }
          />
          <div className="content-wrapper">{children}</div>
        </div>
        <Footer lessor={lessor} />
      </div>
    </div>
  );
};

export default PaymentShell;
