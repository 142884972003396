import React from "react";
import Table, { TableActions } from "components/table";
import { Page } from "components";
import { lesseeRoleTranslations, paymentMethodTranslations } from "lib/intl";
import { ColumnProps } from "antd/lib/table";
import { CustomerList } from "types/fxm";
import LesseePageStore from "./store";
import { toJS } from "lib/mobx";
import { DynamicQueryParameters } from "lib/apiClient";
import { RouteName } from "sitemap";
import { StoreProps, withStore } from "stores";

interface State {
  loading: boolean;
  search?: string;
}
export default withStore(
  class LesseesPage extends React.Component<StoreProps, State> {
    state: Readonly<State> = {
      loading: true
    };

    constructor(props: StoreProps) {
      super(props);
      this.store = new LesseePageStore(props.stores!.rootStore);
    }

    store: LesseePageStore;
    table = React.createRef<Table<CustomerList>>();

    componentDidMount() {
      this.handleTableRequest();
    }

    handleTableRequest = async (query?: DynamicQueryParameters) => {
      this.setState(s => ({ ...s, loading: true }));

      if (!query && this.table.current)
        this.table.current.resetSortAndFilters();

      await this.store.getCustomers(this.state.search, query);

      this.setState(s => ({ ...s, loading: false }));
    };

    handleRowClick = (row: CustomerList) => {
      this.props.stores!.routerStore.navigate(RouteName.Lessee, {
        id: row.id
      });
    };

    onSearch = async (value: string) => {
      await this.setState(s => ({ ...s, search: value }));
      await this.handleTableRequest();
    };

    handleClearFilters = async () => {
      await this.setState(s => ({ ...s, search: undefined }));
      await this.handleTableRequest();
    };

    render() {
      const { loading, search } = this.state;
      const { lessees, pagination } = this.store;

      return (
        <Page
          title="Kunder"
          table
          actions={
            <TableActions
              allowSearch
              searchWidth={300}
              searchValue={search}
              onSearchChanged={val =>
                this.setState(s => ({ ...s, search: val }))
              }
              onSearch={this.onSearch}
              searchPlaceholder="Søg på kunde nr/navn eller faktura nr."
              onClearFilters={this.handleClearFilters}
            />
          }
        >
          <Table<CustomerList>
            ref={this.table}
            rowKey={r => r.id.toString()}
            onRequest={this.handleTableRequest}
            loading={loading}
            dataSource={toJS(lessees)}
            columns={columns}
            pagination={pagination}
            onRow={row => ({
              onClick: () => this.handleRowClick(row)
            })}
          />
        </Page>
      );
    }
  }
);

const columns: ColumnProps<CustomerList>[] = [
  {
    dataIndex: "customerNum",
    title: "Kunde nr.",
    className: "fit right",
    sorter: true
  },
  {
    dataIndex: "name",
    title: "Kunde",
    sorter: true
  },
  {
    dataIndex: "email",
    title: "E-mail",
    sorter: true
  },
  {
    dataIndex: "telephone",
    title: "Tlf.",
    sorter: true
  },
  {
    dataIndex: "role",
    title: "Rolle",
    className: "center fit",
    sorter: true,
    filters: Object.keys(lesseeRoleTranslations).map(x => ({
      value: x,
      text: lesseeRoleTranslations[x]
    })),
    render: (v: string) => lesseeRoleTranslations[v]
  },
  {
    dataIndex: "paymentMethod",
    title: "Betaling",
    className: "center fit",
    sorter: true,
    filters: Object.keys(paymentMethodTranslations).map(x => ({
      value: x,
      text: paymentMethodTranslations[x]
    })),
    render: v => paymentMethodTranslations[v]
  }
];
