import { DynamicQueryParameters } from "lib/apiClient";
import { action, makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "stores";
import {
  CustomerTypeahead,
  Invoice,
  LinkModel,
  OutboundMessage,
  PaginationConfig,
  Party,
  PartyPaymentMeans,
  StatementListEntry
} from "types/fxm";

export default class LesseePageStore {
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      party: observable,
      customerTypeahead: observable,
      paymentMeans: observable,
      invoice: observable,
      loadingStatements: observable,
      statementEntries: observable,
      statementPagination: observable,
      loadingOutboundMessages: observable,
      outboundMessages: observable,
      outboundMessagesPagination: observable,
      resettingPaymentMeans: observable,
      clearLesseesTypeahead: action,
      init: action,
      getParty: action,
      getLesseesTypeahead: action,
      getPaymentMeans: action,
      getStatements: action,
      getOutboundMessages: action
    });

    this.rootStore = rootStore;
  }

  rootStore: RootStore;
  loading: boolean = true;
  party?: Party;
  customerTypeahead: CustomerTypeahead[] = [];
  paymentMeans?: PartyPaymentMeans;
  invoice?: Invoice;

  loadingStatements: boolean = false;
  statementEntries: StatementListEntry[] = [];
  statementPagination?: PaginationConfig;

  loadingOutboundMessages: boolean = false;
  outboundMessages: OutboundMessage[] = [];
  outboundMessagesPagination?: PaginationConfig;

  resettingPaymentMeans: boolean = false;

  init = async (lesseeId: number) => {
    await this.getParty(lesseeId);
    await this.getPaymentMeans(this.party!);

    runInAction(() => {
      this.loading = false;
    });
  };

  getParty = async (id: number) => {
    let response = await this.rootStore.apiClient.request<Party>(
      `/api/customers/${id}`
    );

    runInAction(() => {
      this.party = response.payload!;
    });
  };

  getLesseesTypeahead = async (search: string) => {
    let response = await this.rootStore.apiClient.requestUserLink<
      CustomerTypeahead[]
    >(
      "customersTypeahead",
      { queryParams: { search } },
      { lessorNamespace: this.rootStore.routerStore.lessor }
    );

    runInAction(() => {
      this.customerTypeahead = response.payload || [];
    });
  };

  getInvoice = async (link: LinkModel) => {
    let invoiceResponse = await this.rootStore.apiClient.requestLink<Invoice>(
      link
    );

    runInAction(() => {
      this.invoice = invoiceResponse.payload;
    });
  };

  clearLesseesTypeahead() {
    this.customerTypeahead = [];
  }

  getPaymentMeans = async (lessee: Party) => {
    let response = await this.rootStore.apiClient.requestLink<
      PartyPaymentMeans
    >(lessee.links.paymentMeans);

    runInAction(() => {
      this.paymentMeans = response.payload!;
    });
  };

  emailInvoice = async (
    link: LinkModel,
    recipients: string,
    emailContentHtml: string
  ) => {
    return await this.rootStore.apiClient.requestLink(link, {
      body: { recipients, emailContentHtml }
    });
  };

  getStatements = async (search?: string, query?: DynamicQueryParameters) => {
    this.loadingStatements = true;

    let response = await this.rootStore.apiClient.requestLink<
      StatementListEntry[]
    >(this.party!.links.statements, {
      queryParams: { search },
      query
    });

    runInAction(() => {
      this.statementEntries = response.payload!;
      this.statementPagination = response.pagination;
      this.loadingStatements = false;
    });
  };

  getOutboundMessages = async (query?: DynamicQueryParameters) => {
    this.loadingOutboundMessages = true;

    let response = await this.rootStore.apiClient.requestLink<
      OutboundMessage[]
    >(this.party!.links.outboundMessages, { query });

    runInAction(() => {
      this.outboundMessages = response.payload!;
      this.outboundMessagesPagination = response.pagination;
      this.loadingOutboundMessages = false;
    });
  };

  resetPaymentMeans = async (paymentMeans: PartyPaymentMeans) => {
    this.resettingPaymentMeans = true;

    var response = await this.rootStore.apiClient.requestLink<
      PartyPaymentMeans
    >(paymentMeans.links.reset);

    runInAction(() => {
      this.paymentMeans = response.payload!;
      this.resettingPaymentMeans = false;
    });
  };
}
