import React from "react";
import { FormattedDate } from "react-intl";

const Date = ({ value, time }: { value?: string; time?: boolean }) => {
  if (!value) {
    return null;
  }

  return time ? (
    <FormattedDate
      value={value}
      year="numeric"
      month="2-digit"
      day="2-digit"
      hour12={false}
      hour="2-digit"
      minute="2-digit"
    />
  ) : (
    <FormattedDate value={value} year="numeric" month="2-digit" day="2-digit" />
  );
};

export default Date;
