import { RouterStore } from "stores";
import { State, PluginFactory } from "router5";

export default function mobxRouterPluginFactory(
  routerStore: RouterStore
): PluginFactory {
  return function mobxRouterPlugin(router) {
    return {
      onTransitionSuccess: (toState: State, fromState: State) => {
        routerStore.setCurrent(toState);
        routerStore.setFrom(fromState);
      }
    };
  };
}
