import { saveAs } from "file-saver";
import { action, makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "stores";
import { BillingBasisHeader, LessorNamespace } from "types/fxm";

export default class ImportBillingBasisStore {
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      billingBasis: observable,
      loadingPreview: observable,
      getBillingBasis: action,
      approve: action,
      delete: action,
      downloadInvoicePreview: action,
      downloadSpecificationPreview: action
    });

    this.rootStore = rootStore;
    this.lessorNamespace = rootStore.routerStore.lessor;
  }

  rootStore: RootStore;
  lessorNamespace: LessorNamespace;
  loading: boolean = true;
  billingBasis: BillingBasisHeader[] = [];
  loadingPreview: boolean = false;

  async getBillingBasis() {
    this.loading = true;

    let response = await this.rootStore.apiClient.requestLink<
      BillingBasisHeader[]
    >(this.rootStore.userStore.resources.billingBasis, undefined, {
      lessorNamespace: this.lessorNamespace
    });

    runInAction(() => {
      this.billingBasis = response.payload!;
      this.loading = false;
    });
  }

  async approve(values: object) {
    this.loading = true;

    try {
      await this.rootStore.apiClient.requestLink(
        this.rootStore.userStore.resources.billingBasisApprove,
        { body: values }
      );

      this.rootStore.notificationsStore.success(
        "Faktureringen er udført korrekt",
        "Faktura og specifikationer er sendt til kunderne, og beløbene bliver opkrævet automatisk.",
        10
      );
    } catch (err) {
      this.rootStore.notificationsStore.error(
        "Faktureringen kunne ikke udføres.",
        "Der skete en system-fejl.",
        10
      );
    }

    await this.getBillingBasis();
  }

  async delete(values: object) {
    this.loading = true;

    try {
      await this.rootStore.apiClient.requestLink(
        this.rootStore.userStore.resources.billingBasisDelete,
        { body: values }
      );

      this.rootStore.notificationsStore.success(
        "Rækkerne er blevet slettet.",
        undefined,
        10
      );
    } catch (err) {
      this.rootStore.notificationsStore.error(
        "Rækkerne kunne ikke slettes.",
        "Der skete en system-fejl.",
        10
      );
    }

    await this.getBillingBasis();
  }

  async downloadInvoicePreview(header: BillingBasisHeader) {
    this.loadingPreview = true;

    const typeString = header.totalAmount > 0 ? "faktura" : "kreditnota";
    let fileName = `flexto_${typeString}_${header.invoiceNum}.pdf`;

    let response = await this.rootStore.apiClient.requestLink(
      header.links.previewInvoice
    );

    if (response.blob) {
      saveAs(response.blob, fileName);
    }

    runInAction(() => {
      this.loadingPreview = false;
    });
  }

  async downloadSpecificationPreview(header: BillingBasisHeader) {
    this.loadingPreview = true;

    const typeString = header.totalAmount > 0 ? "faktura" : "kreditnota";
    let fileName = `flexto_${typeString}_${header.invoiceNum}_specification.pdf`;

    let response = await this.rootStore.apiClient.requestLink(
      header.links.previewSpecification
    );

    if (response.blob) {
      saveAs(response.blob, fileName);
    }

    runInAction(() => {
      this.loadingPreview = false;
    });
  }
}
