import { RootStore } from "stores";
import { observable, makeObservable, action, runInAction } from "mobx";
import { PaginationConfig, PaymentListEntry, LinkModel } from "types/fxm";
import { DynamicQueryParameters } from "lib/apiClient";

export default class PaymentsPageStore {
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      payments: observable,
      pagination: observable,
      getPayments: action
    });

    this.rootStore = rootStore;
  }

  rootStore: RootStore;
  payments: PaymentListEntry[] = [];
  pagination?: PaginationConfig;

  async getPayments(search?: string, query?: DynamicQueryParameters) {
    let response = await this.rootStore.apiClient.requestLink<
      PaymentListEntry[]
    >(
      this.rootStore.userStore.resources.payments,
      {
        queryParams: { search },
        query
      },
      { lessorNamespace: this.rootStore.routerStore.lessor }
    );

    runInAction(() => {
      this.payments = response.payload!;
      this.pagination = response.pagination;
    });
  }

  async approve(link: LinkModel) {
    return await this.rootStore.apiClient.requestLink(link);
  }

  async setPaid(link: LinkModel) {
    return await this.rootStore.apiClient.requestLink(link);
  }

  async sendReminder(link: LinkModel) {
    return await this.rootStore.apiClient.requestLink(link);
  }

  async emailInvoice(link: LinkModel) {
    return await this.rootStore.apiClient.requestLink(link);
  }
}
