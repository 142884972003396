import React, { Component } from "react";
import "./index.less";
import { Spin } from "components";

interface LoaderProps {
  text?: string;
  center?: boolean;
}

class Loader extends Component<LoaderProps> {
  render() {
    const { text, center } = this.props;

    let classes = "loader";
    if (center) {
      classes = classes + " center";
    }

    return (
      <div className={classes}>
        <Spin />
        {text && <p>{text}</p>}
      </div>
    );
  }
}

export default Loader;
