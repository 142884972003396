import { ActivationFn, Dependencies, Router, State } from "router5";
import { AppRoute, RouteName } from "sitemap";
import { RouterStore, UserStore } from "stores";

export const redirectToFirstAuthorizedRoute = (
  router: Router,
  dependencies?: Dependencies
): ActivationFn => async (toState: State, fromState: State, done: any) => {
  const appRoutes: Array<AppRoute> = dependencies!.appRoutes;
  // Determine if user is authorized.
  // If not, the user will be redirected to login page.
  await isAuthorized(router, dependencies)(toState, fromState, done);

  const routerStore = dependencies!.routerStore as RouterStore;
  const userStore = dependencies!.userStore as UserStore;
  const appRoute = appRoutes.find(
    x => !!(x.resourcePermission && userStore.resources[x.resourcePermission])
  );

  return Promise.reject<boolean>({
    redirect: {
      name: appRoute ? appRoute.name : RouteName.Forbidden,
      params: { lessor: toState.params.lessor || routerStore.lessor }
    }
  });
};

// Router activation function that determines if the user is authenticated
// and authorized to access the requested route.
export const isAuthorized = (
  router: Router,
  dependencies?: Dependencies
): ActivationFn => async (toState: State, fromState: State, done: any) => {
  const userStore = dependencies!.userStore as UserStore;
  const appRouteEntry = getAppRoute(dependencies!.appRoutes, toState.name);

  // Redirect to idp for authentication if user isn't already authenticated.
  if (!userStore.isAuthenticated) {
    return Promise.reject<boolean>({
      redirect: {
        name: RouteName.Login.toString(),
        params: { requestedState: toState }
      }
    });
  }

  // Redirect to fobidden page if user isn't authorized to this resource.
  if (
    appRouteEntry.resourcePermission &&
    !userStore.resources[appRouteEntry.resourcePermission]
  ) {
    return Promise.reject<boolean>({
      redirect: { name: RouteName.Forbidden.toString() }
    });
  }
  return true;
};

export const getAppRoute = (appRoutes: Array<AppRoute>, routeName: string) =>
  appRoutes.find(x => x.name.toString() === routeName) as AppRoute;
