import React from "react";
import { ConnectedComponent, connect, observer } from "lib/mobx";
import { PaymentShell } from "components/layout";
import { RouterStore } from "stores";
import { PublicPayment } from "types/fxm";
import AuthorizeQuickPaySubscription from "./authorizeQuickPaySubscription";
import CreateLeverandorServiceSubscription from "./createLeverandorServiceSubscription";
import { flextoLessorFake } from "types/fakes";

interface Props {}

interface Stores {
  routerStore: RouterStore;
}

interface State {
  isLoaded: boolean;
  payment?: PublicPayment;
  error: boolean;
}

@connect("routerStore")
@observer
class CreatePaymentSubscriptionPage extends ConnectedComponent<
  Props,
  Stores,
  State
> {
  state: Readonly<State> = {
    isLoaded: false,
    payment: undefined,
    error: false
  };

  async componentDidMount() {
    let { currentState } = this.stores.routerStore;
    let subscriptionId = currentState && currentState.params.subscriptionId;

    if (!subscriptionId) {
      this.setState({ isLoaded: true, error: true });
      return;
    }

    try {
      let res = await fetch(
        `${process.env.REACT_APP_FXM_API_GET_PAYMENT}${subscriptionId}`
      );

      let json = await res.json();

      this.setState({ isLoaded: true, payment: json as PublicPayment });
    } catch (error) {
      this.setState({ isLoaded: true, error: !!error });
    }
  }

  handlePaymentCompleted = () => {
    this.setState(s => ({ ...s, completed: true }));
  };

  render() {
    const { isLoaded, error, payment } = this.state;

    if (!isLoaded) return null;

    if (error || !payment) {
      return (
        <PaymentShell lessor={flextoLessorFake}>
          <h3>Siden blev ikke fundet</h3>
          <p>Kontakt Flexto hvis du har modtaget et link til denne side.</p>
        </PaymentShell>
      );
    }

    if (payment.gateway === "QuickPay") {
      return <AuthorizeQuickPaySubscription payment={payment} />;
    }
    if (payment.gateway === "LeverandorService") {
      return <CreateLeverandorServiceSubscription payment={payment} />;
    }

    return null;
  }
}

export default CreatePaymentSubscriptionPage;
