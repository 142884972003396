import React from "react";
import cx from "classnames";
import { Icon, Loader } from "components";

import "./index.less";

interface PageProps {
  title?: string;
  subtitle?: string;
  subPage?: boolean;
  transparent?: boolean;
  table?: boolean;
  tabs?: boolean;
  cards?: boolean;
  maxSize?: "sm" | "md";
  fixedSize?: boolean;
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  icon?: string;
  loading?: boolean;
  actions?: React.ReactNode;
}

const Page = ({
  title,
  subtitle,
  subPage,
  transparent,
  table,
  tabs,
  cards,
  maxSize,
  fixedSize,
  center,
  children,
  className,
  contentClassName,
  loading = false,
  icon,
  actions
}: PageProps) => {
  const pageClasses = cx("page", className, {
    [`page-size-${maxSize}`]: maxSize,
    [`page-fixed-size-${fixedSize}`]: fixedSize,
    "page-centered": center,
    "page-sub": subPage
  });

  const titleClasses = cx("title", { icon: icon, subtitle });

  const contentClasses = cx("page-content", contentClassName, {
    transparent,
    cards,
    table,
    tabs
  });

  if (loading) {
    return (
      <div className={pageClasses}>
        <div className={contentClasses}>
          <Loader center />
        </div>
      </div>
    );
  }

  return (
    <div className={pageClasses}>
      {title && (
        <div className={titleClasses}>
          {icon && (
            <div className="icon">
              <h1>
                <Icon type={icon} />
              </h1>
            </div>
          )}
          <div className="headers">
            <h1>{title}</h1>
            {subtitle && <span className="subtitle">{subtitle}</span>}
          </div>
          <div className="actions">{actions}</div>
          <div className="clearfix" />
        </div>
      )}
      <div className={contentClasses}>
        {loading ? <Loader center /> : children}
      </div>
    </div>
  );
};

export default Page;
