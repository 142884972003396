import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";

const App = observer(() => {
  const { currentAppRoute } = useStore().routerStore;

  if (currentAppRoute) {
    if (currentAppRoute.shellComponent) {
      return (
        <currentAppRoute.shellComponent>
          <currentAppRoute.component />
        </currentAppRoute.shellComponent>
      );
    } else {
      return <currentAppRoute.component />;
    }
  }

  return null;
});

export default App;
