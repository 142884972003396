import React from "react";
import RootStore, { IRootStore as IRS } from "./RootStore";
export { default as NotificationsStore } from "./NotificationsStore";
export { default as PaymentStore } from "./PaymentStore";
export { default as RootStore } from "./RootStore";
export { default as RouterStore } from "./RouterStore";
export { default as UserStore } from "./UserStore";
export type IRootStore = IRS;

export const rootStore = new RootStore();

const StoreContext = React.createContext<IRootStore>(undefined!);

export const StoreProvider: React.FC<{
  store: IRootStore;
}> = ({ children, store }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

export interface StoreProps {
  stores: IRootStore | undefined;
}

/* HOC to inject store to any functional or class component */
export function withStore<P>(
  WrappedComponent: React.ComponentType<P & StoreProps>
) {
  const ComponentWithStore = (props: P) => {
    return <WrappedComponent {...props} stores={useStore()} />;
  };

  return ComponentWithStore;
}
