import RootStore from "./RootStore";
import { LinksModel } from "types/fxm";
import { observable, makeObservable, runInAction } from "mobx";
import UserManager from "lib/auth/userManager";
import { RouteName } from "sitemap";
import { State } from "router5";

export default class UserStore {
  rootStore: RootStore;

  initialized = false;
  isAuthenticated = false;
  accessToken?: string;
  resources: LinksModel = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      initialized: observable,
      isAuthenticated: observable,
      accessToken: observable,
      resources: observable
    });

    this.rootStore = rootStore;

    UserManager.events.addAccessTokenExpiring(async () => {
      this.renewSilent();
    });
  }

  async loginRedirect(requestedState?: string) {
    const user = await UserManager.getUser();

    if (requestedState)
      sessionStorage.setItem("loginRedirect", JSON.stringify(requestedState));

    if (!user || user.expired) {
      UserManager.signinRedirect();
    } else {
      this.handleAuthenticated();
    }
  }

  async loginCallback() {
    await UserManager.signinRedirectCallback();
    await this.handleAuthenticated();
  }

  async loginRenew() {
    await UserManager.signinSilentCallback();
  }

  async logout() {
    runInAction(() => {
      this.isAuthenticated = false;
    });
    await UserManager.signoutRedirect();
  }

  async handleAuthenticated() {
    const user = await UserManager.getUser();

    runInAction(() => {
      this.accessToken = user!.access_token;
      this.isAuthenticated = true;
    });

    await this.initialize();

    let loginRedirectState = sessionStorage.getItem("loginRedirect");

    if (loginRedirectState) {
      sessionStorage.removeItem("loginRedirect");

      const requestedState = JSON.parse(loginRedirectState) as State;

      this.rootStore.routerStore.navigate(
        requestedState.name,
        requestedState.params
      );
    } else {
      this.rootStore.routerStore.navigate(RouteName.Lessees);
    }
  }

  async renewSilent() {
    let user = await UserManager.signinSilent();

    runInAction(() => {
      this.accessToken = user!.access_token;
    });

    await this.initialize();
  }

  async initialize() {
    let response = await this.rootStore.apiClient.getRoot();

    runInAction(() => {
      this.resources = response.payload!.links;
      this.initialized = true;
    });
  }
}
