import React from "react";
import CardShell from "components/layout/cardShell";

const NotFoundPage = () => (
  <CardShell>
    <span>Siden blev ikke fundet.</span>
  </CardShell>
);

export default NotFoundPage;
