import React, { useEffect } from "react";
import { useStore } from "stores";

export const LoginRenewPage = () => {
  const store = useStore();

  useEffect(() => {
    store.userStore.loginRenew();
  }, [store]);

  return <p>Redirecting...</p>;
};
