import React from "react";
import { Icon } from "components";

import "./index.less";

interface Props {
  icon?: string;
  text?: string;
  href?: string;
  title?: string;
  children?: React.ReactNode;
}

export default ({ icon, text, href, children, title }: Props) => {
  let content: string | React.ReactNode;

  if (!text && !children) return null;

  content = <span>{text}</span>;

  if (children) {
    content = children;
  } else if (href) content = <a href={href}>{text}</a>;

  return (
    <div className="label" title={title}>
      {icon && <Icon type={icon} />}
      {content}
    </div>
  );
};
