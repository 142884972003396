import React from "react";
import { FilterDropdownProps } from "..";
import { DatePicker } from "antd";
import { Moment } from "moment";
import { ButtonLink } from "components";

interface Props extends FilterDropdownProps {}
interface State {
  from?: Moment | null;
  to?: Moment | null;
}

export default class DateRangeFilterDropdown extends React.Component<
  Props,
  State
> {
  handleFromChange = async (val: Moment | null) => {
    await this.setState(s => ({ ...s, from: val }));
    this.setKeys();
  };
  handleToChange = async (val: Moment | null) => {
    await this.setState(s => ({ ...s, to: val }));
    this.setKeys();
  };

  setKeys = () => {
    const { from, to } = this.state;

    // Ensure that both from and/or to is always set when the other is set.
    this.props.setSelectedKeys([from || to, to || from].filter(x => !!x));
  };

  render() {
    const { selectedKeys, clearFilters, confirm } = this.props;

    return (
      <div style={{ padding: 8 }}>
        <DatePicker
          placeholder="Fra dato"
          onChange={this.handleFromChange}
          value={selectedKeys[0]}
          allowClear={false}
          style={{ width: 120 }}
          format="DD-MM-YYYY"
        />
        {" - "}
        <DatePicker
          placeholder="Til dato"
          onChange={this.handleToChange}
          value={selectedKeys[1]}
          allowClear={false}
          style={{ width: 120 }}
          format="DD-MM-YYYY"
        />
        <div style={{ display: "inline", paddingLeft: 7, paddingRight: 7 }}>
          <ButtonLink icon="search-o" onClick={confirm} />
        </div>
        <div style={{ display: "inline", paddingLeft: 7, paddingRight: 7 }}>
          <ButtonLink icon="close-o" onClick={clearFilters} />
        </div>
      </div>
    );
  }
}
