import { Form, Select } from "@jbuschke/formik-antd";
import { Modal, Spin } from "components";
import { TextEditor } from "components/textEditor";
import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Invoice, StatementListEntry } from "types/fxm";
import * as Yup from "yup";
import LesseePageStore from "../store";

const parseEmails = (invoice: Invoice | undefined) => {
  if (!invoice) return;
  const emails = invoice.customer.invoiceEmail || invoice.customer.email;
  if (emails) return emails.split(";");
};

export const EmailInvoiceModal = observer(
  ({
    statementListEntry,
    store: {
      emailInvoice,
      getInvoice,
      invoice,
      rootStore: { notificationsStore }
    },
    onClose
  }: {
    statementListEntry?: StatementListEntry;
    store: LesseePageStore;
    onClose: () => void;
  }) => {
    useEffect(() => {
      if (statementListEntry) getInvoice(statementListEntry.links.invoice);
    }, [statementListEntry, getInvoice]);

    if (!invoice) {
      return (
        <Modal>
          <Spin />
        </Modal>
      );
    }

    return (
      <Formik<FormValues>
        onSubmit={async (values, actions) => {
          const recipients = values.recipients!.join(",");
          await emailInvoice(
            statementListEntry!.links.emailInvoice,
            recipients,
            values.emailContentHtml || ""
          );
          notificationsStore.success("E-mail afsendt.");
          actions.setSubmitting(false);
          onClose();
        }}
        initialValues={{
          recipients: parseEmails(invoice),
          emailContentHtml: `<p>Kære ${
            invoice ? invoice.customer.name : "kunde"
          }</p><p>Vi fremsender hermed faktura ${
            invoice ? invoice.invoiceNum : "#"
          }.</p><p>Med venlig hilsen<br>Bogholderiet</p>`
        }}
        validationSchema={FormValuesSchema}
        enableReinitialize
        isInitialValid
        render={form => (
          <Modal
            width="80%"
            title="E-mail faktura"
            visible={!!statementListEntry}
            okText="Send faktura"
            onOk={() => form.submitForm()}
            okButtonProps={{
              loading: form.isSubmitting,
              disabled: form.isValidating || !form.isValid
            }}
            cancelText="Annuller"
            onCancel={onClose}
            cancelButtonProps={{ disabled: form.isSubmitting }}
          >
            <Spin spinning={!invoice}>
              <p>
                Gensend faktura og vedhæftninger eller videresend til andre
                modtager.
              </p>
              <Form.Item name="recipients" label="Modtagere">
                <Select name="recipients" style={{ width: "100%" }} mode="tags">
                  {form.values.recipients &&
                    form.values.recipients.map(x => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="contentHtml"
                label="Email tekst"
                help="Tryk <Enter> for ny paragraf og <Shift+Enter> for linjeskift."
              >
                <TextEditor
                  value={form.values.emailContentHtml}
                  setFieldValue={v => form.setFieldValue("emailContentHtml", v)}
                />
              </Form.Item>
            </Spin>
          </Modal>
        )}
      />
    );
  }
);

interface FormValues {
  recipients: string[] | undefined;
  emailContentHtml: string | undefined;
}

const FormValuesSchema = Yup.object<FormValues>().shape({
  recipients: Yup.array()
    .of(Yup.string().email("Ugyldig e-mail adresse."))
    .min(1, "Der skal angives mindst én e-mail adresse.")
});
