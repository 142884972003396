import "babel-polyfill";
import { configure } from "mobx";
import { Provider } from "mobx-react";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { StoreProvider, rootStore } from "stores";
import "styles/core.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

configure({ enforceActions: "always" });

const Root = () => (
  <StoreProvider store={rootStore}>
    <Provider {...rootStore.stores}>
      <IntlProvider locale="da">
        <App />
      </IntlProvider>
    </Provider>
  </StoreProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
