import React from "react";
import { Lessor } from "types/fxm";

import "./index.less";

export default ({ lessor }: { lessor: Lessor }) => (
  <div className="footer">
    <span className="address">
      {lessor.party.name}, {lessor.party.address.line1},{" "}
      {lessor.party.address.postalCode} {lessor.party.address.cityName}
    </span>
    <span className="phone">
      Tlf.:{" "}
      <a href={`tel:${lessor.party.telephone}`}>{lessor.party.telephone}</a>
    </span>
    <span className="email">
      E-mail: <a href={`mailto:${lessor.party.email}`}>{lessor.party.email}</a>
    </span>
    <span className="cvr">CVR: {lessor.party.cvr}</span>
    <span className="version">v. {process.env.REACT_APP_VERSION}</span>
  </div>
);
