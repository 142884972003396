import React from "react";
import { Label, BBAN } from "components";
import { Party } from "types/fxm";

export default ({ party }: { party: Party }) => (
  <React.Fragment>
    <section>
      <Label text={`Kunde nr. ${party.customerNum}`} />
    </section>
    <address>
      {party.name}
      <br />
      {party.address.line1}
      <br />
      {party.address.line2 && (
        <span>
          {party.address.line2}
          <br />
        </span>
      )}
      {party.address.postalCode} {party.address.cityName}
    </address>
    <Label
      icon="mail"
      title="E-mail"
      text={party.email ? party.email : "-"}
      href={party.email && `mailto:${party.email}`}
    />
    <Label
      icon="phone"
      title="Telefonnummer"
      text={party.telephone ? party.telephone : "-"}
      href={party.telephone && `tel:${party.telephone}`}
    />
    {party.bban && (
      <Label icon="bank" title="Bankkonto">
        <BBAN value={party.bban} />
      </Label>
    )}
  </React.Fragment>
);
