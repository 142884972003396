import ApiClient from "lib/apiClient";
import mobxRouterPluginFactory from "lib/routing/mobxRouterPluginFactory";
import createRouter from "router5";
import browserPlugin from "router5-plugin-browser";
import { appRoutes, RouteName } from "sitemap";
import {
  NotificationsStore,
  PaymentStore,
  RouterStore,
  UserStore
} from "stores";

export interface IRootStore {
  readonly rootStore: RootStore;
  readonly userStore: UserStore;
  readonly routerStore: RouterStore;
  readonly notificationsStore: NotificationsStore;
  readonly paymentStore: PaymentStore;
}

export default class RootStore implements IRootStore {
  constructor() {
    const router = createRouter(appRoutes, {
      defaultRoute: RouteName.NotFound
    });
    this.routerStore = new RouterStore(this, router);

    router.setDependencies({ ...this, appRoutes });

    router.usePlugin(
      browserPlugin(),
      mobxRouterPluginFactory(this.routerStore)
    );
    router.start();
  }

  rootStore = this;
  routerStore: RouterStore;
  userStore = new UserStore(this);
  notificationsStore = new NotificationsStore(this);
  paymentStore = new PaymentStore(this);

  apiClient = new ApiClient(this.userStore);

  get stores() {
    return {
      rootStore: this.rootStore,
      userStore: this.userStore,
      routerStore: this.routerStore,
      notificationsStore: this.notificationsStore,
      paymentStore: this.paymentStore
    };
  }
}
