import React from "react";
import { ConnectedComponent, connect, observer } from "lib/mobx";
import { ButtonLink } from "components";
import { PaymentShell } from "components/layout";
import { RouterStore } from "stores";
import { PublicPayment } from "types/fxm";
import Form, { FormValues } from "./Form";
import FlextoTermsLS from "../FlextoTermsLS";
import FlextoSplitTerms from "../FlextoSplitTerms";

interface Props {
  payment: PublicPayment;
}

interface Stores {
  routerStore: RouterStore;
}

interface State {
  showTerms: boolean;
  completed: boolean;
}

@connect("routerStore")
@observer
class CreateLeverandorServiceSubscription extends ConnectedComponent<
  Props,
  Stores,
  State
> {
  state: Readonly<State> = {
    showTerms: false,
    completed: false
  };

  togglTerms = () => {
    this.setState(s => ({ ...s, showTerms: !s.showTerms }));
  };

  handleSubmit = async (values: FormValues) => {
    const { payment } = this.props;
    const link = payment.links[0];

    await fetch(link.href, {
      method: link.method,
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json"
      }
    });

    this.setState(s => ({ ...s, completed: true }));
  };

  render() {
    const { payment } = this.props;
    const { completed, showTerms } = this.state;

    if (showTerms) {
      if (payment.lessor.namespace === "flextoSplit")
        return (
          <FlextoSplitTerms onClose={this.togglTerms} lessor={payment.lessor} />
        );
      else
        return (
          <FlextoTermsLS onClose={this.togglTerms} lessor={payment.lessor} />
        );
    }

    if (completed) {
      return (
        <PaymentShell lessor={payment.lessor}>
          <h3>Kære {payment.customerName}</h3>

          <p>Tak for din tilmelding.</p>

          <p>
            Dine afregninger vil automatisk blive opkrævet, og du vil modtage
            faktura pr. e-mail.
          </p>
        </PaymentShell>
      );
    }

    if (payment.isSubscribed) {
      return (
        <PaymentShell lessor={payment.lessor}>
          <h3>Kære {payment.customerName}</h3>

          <p>Velkommen til vores betalingsportal.</p>

          <p>Jeres virksomhed er allerede tilmeldt LeverandørService.</p>
          <p>Fremtidige afregninger vil automatisk blive opkrævet/udbetalt.</p>
        </PaymentShell>
      );
    }

    return (
      <PaymentShell lessor={payment.lessor}>
        <h3>Kære {payment.customerName}</h3>

        <p>Velkommen til vores betalingsportal.</p>

        <p>
          Før vi automatisk kan opkræve månedlige fakturaer, skal Jeres
          virksomhed tilmeldes LeverandørService.
        </p>

        <p>
          Indtast bankkontoens registrerings- og kontonummer her under, så vil
          vi sørge for at tilmelde denne til LeverandørService.
        </p>

        <p>
          Ved tryk på betal/tilmeld, accepterer du vores handelsbetingelser.{" "}
          <ButtonLink onClick={this.togglTerms}>
            Du kan læse dem her.
          </ButtonLink>
        </p>

        <h3>Tilmeld LeverandørService</h3>
        <Form onSubmit={this.handleSubmit} />
      </PaymentShell>
    );
  }
}

export default CreateLeverandorServiceSubscription;
