import { RootStore } from "stores";
import { observable, autorun, makeObservable, runInAction } from "mobx";
import { PaymentStatesResponse } from "types/fxm";

export default class PaymentStore {
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      paymentStates: observable,
      quickPayPaymentStates: observable
    });

    this.rootStore = rootStore;

    autorun(() => {
      if (this.rootStore.userStore.initialized) {
        this.getPaymentStates();
      }
    });
  }

  rootStore: RootStore;
  paymentStates: string[] = [];
  quickPayPaymentStates: string[] = [];

  async getPaymentStates() {
    let response = await this.rootStore.apiClient.requestLink<
      PaymentStatesResponse
    >(this.rootStore.userStore.resources.paymentStates);

    runInAction(() => {
      this.paymentStates = response.payload!.paymentStates;
      this.quickPayPaymentStates = response.payload!.quickPayPaymentStates;
    });
  }
}
