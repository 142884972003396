import React from "react";
import { AutoComplete, Input, Icon } from "components";
import { CustomerTypeahead } from "types/fxm";
import "./index.less";

interface Props {
  dataSource: CustomerTypeahead[];
  onSearch: (value: string) => void;
  onSelect: (lesseeId: number) => any;
}

export default ({ dataSource, onSearch, onSelect }: Props) => {
  const options = dataSource.map((x) => (
    <AutoComplete.Option key={x.id} value={x.id.toString()} title={x.name}>
      <div className="item">
        <span className="item-desc">
          <Icon type={x.role === "Private" ? "user" : "bank"} />
          {x.name}
        </span>
        <span className="item-num">{x.customerNum}</span>
      </div>
      <div className="sub-item">
        <span className="item-desc">{x.vehicleModelName}</span>
        <span className="item-num">{x.vehicleNum}</span>
      </div>
    </AutoComplete.Option>
  ));

  return (
    <AutoComplete
      className="lessee-selector"
      placeholder="Skift kunde"
      onSearch={(v) => onSearch(v)}
      onSelect={(v) => onSelect(parseInt(v as string))}
      dataSource={options}
      dropdownMatchSelectWidth={true}
      dropdownClassName="lessee-selector-dropdown-container"
      optionLabelProp="title"
    >
      <Input suffix={<Icon type="search" />} />
    </AutoComplete>
  );
};
