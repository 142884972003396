import React from "react";
import Visa from "./Visa";
import Nets from "./Nets";

import "./index.less";
import { PaymentCard, LeverandorServiceSubscription } from "types/fxm";

interface Props {
  customerNum: string;
  payeeName: string;
  paymentCard?: PaymentCard;
  leverandorServiceSubscription?: LeverandorServiceSubscription;
}

export default ({
  payeeName,
  customerNum,
  paymentCard,
  leverandorServiceSubscription
}: Props) => {
  if (paymentCard)
    return <Visa creditCard={{ cardholderName: payeeName, ...paymentCard }} />;
  else if (leverandorServiceSubscription)
    return (
      <Nets
        accountName={payeeName}
        accountNum={customerNum}
        bankRegistrationNum={leverandorServiceSubscription.bankRegistrationNum}
        bankAccountNum={leverandorServiceSubscription.bankAccountNum}
      />
    );

  return null;
};
