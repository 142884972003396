import React from "react";
import { Input, ButtonLink } from "components";
import { FilterDropdownProps } from "..";

interface Props extends FilterDropdownProps {
  placeholder?: string;
  width?: number;
}

const InputFilterDropdown = ({
  placeholder,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  width = 100
}: Props) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={placeholder}
      value={selectedKeys[0]}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={confirm}
      style={{ width }}
    />
    <div style={{ display: "inline", paddingLeft: 7, paddingRight: 7 }}>
      <ButtonLink icon="search-o" onClick={confirm} />
    </div>
    <div style={{ display: "inline", paddingLeft: 7, paddingRight: 7 }}>
      <ButtonLink icon="close-o" onClick={clearFilters} />
    </div>
  </div>
);

export default InputFilterDropdown;
