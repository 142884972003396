import { saveAs } from "file-saver";
import { readBlobAsDataURL } from "lib";
import { action, makeObservable, observable, runInAction } from "mobx";
import { RootStore, rootStore } from "stores";
import { Invoice, InvoiceAttachment, LinkModel } from "types/fxm";

export class Store {
  constructor() {
    makeObservable(this, {
      invoice: observable,
      attachments: observable,
      html: observable,
      loading: observable,
      downloadInvoice: action,
      getInvoice: action
    });
  }

  rootStore: RootStore = rootStore;
  invoice?: Invoice;
  html?: string;
  loading?: boolean;
  attachments: InvoiceAttachment[] = [];

  getInvoice = async (link: LinkModel) => {
    this.loading = true;

    let invoiceResponse = await this.rootStore.apiClient.requestLink<Invoice>(
      link
    );

    let html: string | undefined;

    if (invoiceResponse.payload && invoiceResponse.payload.links["html"]) {
      let htmlResponse = await this.rootStore.apiClient.requestLink<string>(
        invoiceResponse.payload.links["html"]
      );
      html = await readBlobAsDataURL(htmlResponse.blob!);
    }

    runInAction(() => {
      this.invoice = invoiceResponse.payload;
      this.html = html;
      this.loading = false;
    });
  };

  downloadInvoice = async (invoice: Invoice) => {
    const typeString = invoice.isCreditNote ? "kreditnota" : "faktura";
    let fileName = `flexto_${typeString}_${invoice.invoiceNum}.pdf`;

    let response = await this.rootStore.apiClient.requestLink(
      invoice.links.pdf
    );

    if (response.blob) {
      saveAs(response.blob, fileName);
    }
  };

  getAttachments = async (invoice: Invoice) => {
    const link = invoice.links["attachments"];
    const response = await this.rootStore.apiClient.requestLink<
      InvoiceAttachment[]
    >(link);
    runInAction(() => {
      this.attachments = response.payload || [];
    });
  };

  downloadAttachment = async (attachment: InvoiceAttachment) => {
    let response = await this.rootStore.apiClient.requestLink(
      attachment.links.download
    );

    if (response.blob) {
      saveAs(response.blob, attachment.fileName);
    }
  };

  // downloadSpecification = async (statementEntry: StatementListEntry) => {
  //   const typeString = statementEntry.amount > 0 ? "faktura" : "kreditnota";
  //   const fileName = `flexto_${typeString}_${statementEntry.invoiceNum}_specifikation.pdf`;

  //   let response = await this.rootStore.apiClient.requestLink(
  //     statementEntry.links.specificationPdf
  //   );

  //   if (response.blob) {
  //     saveAs(response.blob, fileName);
  //   }
  // };
}
