import React, { ReactNode } from "react";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { UserStore, RouterStore } from "stores";
import { menuEntries, SubMenuEntry, MenuEntry } from "sitemap";
import { LinksModel } from "types/fxm";

interface Props {
  userStore: UserStore;
  routerStore: RouterStore;
}

const buildMenu = (
  menuEntries: Array<SubMenuEntry | MenuEntry>,
  userPermissions: LinksModel
): ReactNode[] => {
  return menuEntries.reduce((acc, route) => {
    if ((route as SubMenuEntry).routes) {
      let subMenuEntry = route as SubMenuEntry;
      let subMenuRoutes = subMenuEntry.routes as Array<
        SubMenuEntry | MenuEntry
      >;
      let subMenuItems = buildMenu(subMenuRoutes, userPermissions);

      if (subMenuItems.length > 0) {
        acc.push(
          <Menu.SubMenu key={route.text} title={route.text}>
            {subMenuItems}
          </Menu.SubMenu>
        );
      }
    } else {
      let menuEntry = route as MenuEntry;
      if (
        !menuEntry.showInOptionsMenu &&
        (!menuEntry.resourcePermission ||
          (menuEntry.resourcePermission &&
            userPermissions[menuEntry.resourcePermission]))
      )
        acc.push(
          <Menu.Item key={(route as MenuEntry).name}>{route.text}</Menu.Item>
        );
    }

    return acc;
  }, new Array<ReactNode>());
};

export default class AdminMenu extends React.Component<Props> {
  handleClick = (e: ClickParam) => {
    this.props.routerStore.navigate(e.key);
  };

  render() {
    const { userStore, routerStore } = this.props;
    return (
      <Menu
        theme="dark"
        mode="horizontal"
        onClick={this.handleClick}
        selectedKeys={[routerStore.currentAppRoute!.name]}
      >
        {buildMenu(menuEntries, userStore.resources)}
      </Menu>
    );
  }
}
