import React from "react";
import { Layout, Breadcrumb } from "antd";
import cx from "classnames";
import { ConnectedComponent, connect, observer } from "lib/mobx";
import { RouterStore, UserStore } from "stores";
import AdminMenu from "./AdminMenu";
import "./index.less";
import HeaderNotifications from "./HeaderNotifications";
import { RouteName, getRouteBreadcrumbs } from "sitemap";

const { Header, Content } = Layout;

interface Props {
  loading?: boolean;
}

interface Stores {
  routerStore: RouterStore;
  userStore: UserStore;
}

@connect(
  "routerStore",
  "userStore"
)
@observer
class AdminShell extends ConnectedComponent<Props, Stores> {
  render() {
    const { loading, children } = this.props;
    const { currentAppRoute, lessor } = this.stores.routerStore;

    let breadcrumbs = currentAppRoute
      ? getRouteBreadcrumbs(currentAppRoute)
      : [];

    if (loading) return null;

    return (
      <Layout>
        <Header className="header">
          <div className="logo">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 153.7 19.8"
              xmlSpace="preserve"
            >
              <rect
                id="Rectangle_34"
                x="15.1"
                className="logo0"
                width="10.1"
                height="4.1"
              ></rect>
              <rect
                id="Rectangle_35"
                x="7.5"
                y="7.9"
                className="logo1"
                width="17.6"
                height="4.1"
              ></rect>
              <rect
                id="Rectangle_36"
                y="15.7"
                className="logo2"
                width="25.1"
                height="4.1"
              ></rect>
              <path
                id="Path_181"
                className="logo2"
                d="M143.8,19.8c-5.5,0-9.9-4.4-9.9-9.9c0-5.5,4.4-9.9,9.9-9.9c5.5,0,9.9,4.4,9.9,9.9c0,0,0,0,0,0 C153.7,15.4,149.3,19.8,143.8,19.8z M143.8,4.2c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7v0 C149.6,6.8,147,4.2,143.8,4.2L143.8,4.2z"
              ></path>
              <rect
                id="Rectangle_40"
                x="33.2"
                className="logo2"
                width="15.8"
                height="4.1"
              ></rect>
              <path
                id="Path_182"
                className="logo2"
                d="M112.2,19.8l-7.5-10l7.4-9.8h-5l-4.9,6.5L97.3,0h-5l7.4,9.8l-7.5,10h5l5-6.6l5,6.6H112.2z"
              ></path>
              <path
                id="Path_183"
                className="logo2"
                d="M58.1,0h-4.1v19.8h13.9v-4.1h-9.8L58.1,0z"
              ></path>
              <path
                id="Path_184"
                className="logo2"
                d="M37.2,19.8v-7.9h7V7.9H33.2v11.9H37.2z"
              ></path>
              <rect
                id="Rectangle_41"
                x="115.6"
                y="0.1"
                className="logo2"
                width="15.8"
                height="4.1"
              ></rect>
              <rect
                id="Rectangle_42"
                x="121.5"
                y="7.9"
                className="logo2"
                width="4.1"
                height="11.9"
              ></rect>
              <rect
                id="Rectangle_37"
                x="72.7"
                className="logo2"
                width="15.8"
                height="4.1"
              ></rect>
              <rect
                id="Rectangle_38"
                x="72.7"
                y="15.7"
                className="logo2"
                width="15.8"
                height="4.1"
              ></rect>
              <rect
                id="Rectangle_39"
                x="72.7"
                y="7.8"
                className="logo2"
                width="11.1"
                height="4.1"
              ></rect>
            </svg>
          </div>
          <AdminMenu
            userStore={this.stores.userStore}
            routerStore={this.stores.routerStore}
          />
          <div className="lessor-picker">
            <div
              className={cx({ active: lessor === "flexto" })}
              onClick={() =>
                this.stores.routerStore.navigate(RouteName.Admin, {
                  lessor: "flexto"
                })
              }
            >
              Flexto
            </div>
            <div
              className={cx({ active: lessor === "flextoSplit" })}
              onClick={() =>
                this.stores.routerStore.navigate(RouteName.Admin, {
                  lessor: "flextosplit"
                })
              }
            >
              Flexto Splitleasing
            </div>
          </div>
        </Header>
        <HeaderNotifications className="header-alert" />
        <Content>
          <Breadcrumb>
            <Breadcrumb.Item>
              {lessor === "flexto" ? "Flexto" : "Flexto Splitleasing"}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Administration</Breadcrumb.Item>
            {breadcrumbs.length > 0 &&
              breadcrumbs.map(x => (
                <Breadcrumb.Item key={x}>{x}</Breadcrumb.Item>
              ))}
          </Breadcrumb>
          {children}
        </Content>
        {/* <AntFooter>
          <Footer  />
        </AntFooter> */}
      </Layout>
    );
  }
}

export default AdminShell;
