// Contains helper methods and classes for injecting strongly typed stores into components.

import { Component } from "react";
import { IRootStore } from "stores/RootStore";
import { inject } from "mobx-react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

export { observer, toJS };

export class ConnectedComponent<TProps, TStores, TState = {}> extends Component<
  TProps,
  TState
> {
  public get stores() {
    return (this.props as any) as TStores;
  }
}

export const connect = (...args: Array<keyof IRootStore>) => inject(...args);
