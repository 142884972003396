import React from "react";
import { Alert } from "antd";
import { ConnectedComponent, connect, observer } from "lib/mobx";
import NotificationsStore from "stores/NotificationsStore";

interface Props {
  className?: string;
}

interface Stores {
  notificationsStore: NotificationsStore;
}

@connect("notificationsStore")
@observer
class HeaderNotifications extends ConnectedComponent<Props, Stores> {
  render() {
    const { className } = this.props;
    const { notifications, remove } = this.stores.notificationsStore;

    return Array.from(notifications).map(t => {
      let id = t[0];
      let n = t[1];
      return (
        <Alert
          banner
          key={id}
          type={n.type}
          message={n.message}
          description={n.description}
          className={className}
          onClose={() => remove(id)}
          closable
        />
      );
    });
  }
}

export default HeaderNotifications;
