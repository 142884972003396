import React from "react";
import { Row, Col, Icon } from "antd";

import Logo from "assets/img/visa-logo.png";
import { PaymentCard } from "types/fxm";

interface VisaCard extends PaymentCard {
  cardholderName: string;
}

interface Props {
  creditCard: VisaCard;
  onChange?: () => void; // Todo.
}

export default ({ creditCard, onChange }: Props) => {
  const cardNumber = `${creditCard.bin.padEnd(12, "·")}${
    creditCard.last4
  }`.replace(/(.{4})/g, "$1 ");
  return (
    <div className="payment-card">
      <Row type="flex" gutter={16}>
        <Col xs={12}>
          <img alt="Logo" src={Logo} className="payment-logo-visa" />
        </Col>
        {onChange && (
          <Col xs={12}>
            <a href="not-implemented" className="payment-option">
              Skift aftale
              <Icon type="swap" theme="outlined" />
            </a>
          </Col>
        )}
      </Row>
      <h1 className="card-number">
        <small className="card-number-label payment-label">Kort nr.</small>
        <span>{cardNumber}</span>
      </h1>
      <Row type="flex" gutter={16}>
        <Col xs={12}>
          <small className="payment-label">Kortholder</small>
          <span>{creditCard.cardholderName}</span>
        </Col>
        <Col xs={12}>
          <small className="payment-label">Udløber</small>
          <span>{`${creditCard.expiryMonth}/${creditCard.expiryYear}`}</span>
        </Col>
      </Row>
    </div>
  );
};
