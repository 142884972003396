import React from "react";
import { FormattedNumber } from "react-intl";

const Number = (props: { value?: number }) => {
  if (!props.value) {
    return null;
  }

  return (
    <FormattedNumber
      value={props.value}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />
  );
};

export default Number;
