import React, { useEffect } from "react";
import { useStore } from "stores";

export const LoginRedirectPage = () => {
  const store = useStore();

  useEffect(() => {
    let requestedState = undefined;
    var currentState = store.routerStore.currentState;
    if (currentState) {
      requestedState = currentState.params.requestedState;
    }

    store.userStore.loginRedirect(requestedState);
  }, [store]);

  return <p>Redirecting...</p>;
};
