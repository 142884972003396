// Line indexOf, but returns an array of indexes with all matches.
export const multiIndexOf = (str: string, letter: string) => {
  const indexes: Array<number> = [];
  str.split("").forEach((l, i) => {
    if (l === letter) indexes.push(i);
  });
  return indexes;
};

// Camelizes a string (supports dot-notation)
// Eg. Transactions.TransactionAmount to transactions.transactionAmount.
export const camelize = (str: string) => {
  const wordBeginningIndexes = multiIndexOf(str, ".");
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index === 0 || wordBeginningIndexes.includes(index - 1)
        ? letter.toLowerCase()
        : letter.toUpperCase()
    )
    .replace(/\s+/g, "");
};

// Converts an object into dot notation.
export const dotify = (obj: any) => {
  const res: { [index: string]: any } = {};
  function recurse(obj: any, current?: any) {
    for (const key in obj) {
      const value = obj[key];
      const newKey = current ? `${current}.${key}` : key; // joined key with dot
      if (value && typeof value === "object") {
        recurse(value, newKey); // it's a nested object, so do it again
      } else {
        res[newKey] = value; // it's not an object, so set the property
      }
    }
  }

  recurse(obj);
  return res;
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const readBlobAsText = (blob: Blob) => {
  const temporaryFileReader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result as string);
    };
    temporaryFileReader.readAsText(blob);
  });
};

export const readBlobAsDataURL = (blob: Blob) => {
  const temporaryFileReader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result as string);
    };
    temporaryFileReader.readAsDataURL(blob);
  });
};
