import {
  ButtonLink,
  Date,
  Dropdown,
  InvoicePreviewDrawer,
  Menu,
  Number
} from "components";
import { PaymentStateLabel } from "components/payments";
import Table, {
  DateRangeFilterDropdown,
  FilterDropdownProps,
  InputNumberFilterDropdown
} from "components/table";
import { DynamicQueryParameters } from "lib/apiClient";
import { paymentGatewayTranslations, paymentStateTranslations } from "lib/intl";
import { observer, toJS } from "lib/mobx";
import React from "react";
import { StatementListEntry } from "types/fxm";
import LesseePageStore from "../store";
import { EmailInvoiceModal } from "./EmailInvoiceModal";

interface Props {
  pageStore: LesseePageStore;
}

interface State {
  search?: string;
  showInvoice?: StatementListEntry;
  showEmail?: StatementListEntry;
}

@observer
export default class StatementsTab extends React.Component<Props> {
  state: Readonly<State> = {
    search: undefined,
    showInvoice: undefined,
    showEmail: undefined
  };

  table = React.createRef<Table<StatementListEntry>>();

  componentDidMount() {
    this.handleTableRequest();
  }

  handleTableRequest = async (query?: DynamicQueryParameters) => {
    if (!query && this.table.current) this.table.current.resetSortAndFilters();
    await this.props.pageStore.getStatements(this.state.search, query);
  };

  onSearch = async (value: string) => {
    this.setState(s => ({ ...s, search: value }));
    await this.handleTableRequest();
  };

  handleClearFilters = async () => {
    this.setState(s => ({ ...s, search: undefined }));
    await this.handleTableRequest();
  };

  showInvoice = (se: StatementListEntry) => {
    this.setState(s => ({ ...s, showInvoice: se }));
  };

  closeInvoice = () => {
    this.setState(s => ({ ...s, showInvoice: undefined }));
  };

  showEmailModal = (se: StatementListEntry) => {
    this.setState(s => ({ ...s, showEmail: se }));
  };

  closeEmailModal = () => {
    this.setState(s => ({ ...s, showEmail: undefined }));
  };

  render() {
    const {
      statementEntries,
      statementPagination,
      loadingStatements
    } = this.props.pageStore;

    const { showInvoice, showEmail } = this.state;

    const columns = [
      {
        dataIndex: "date",
        title: "Dato",
        className: "fit right",
        sorter: true,
        render: (v: string) => <Date value={v} />,
        filterDropdown: (props: FilterDropdownProps) => (
          <DateRangeFilterDropdown {...props} />
        )
      },
      {
        dataIndex: "invoiceNum",
        title: "Faktura nr.",
        className: "right",
        sorter: true,
        filterDropdown: (props: FilterDropdownProps) => (
          <InputNumberFilterDropdown {...props} />
        )
      },
      {
        dataIndex: "amount",
        title: "Beløb",
        className: "right",
        sorter: true,
        render: (v: number) => <Number value={v} />,
        filterDropdown: (props: FilterDropdownProps) => (
          <InputNumberFilterDropdown {...props} />
        )
      },
      {
        dataIndex: "dueDate",
        title: "Forfaldsdato",
        className: "right",
        sorter: true,
        render: (v: string) => <Date value={v} />,
        filterDropdown: (props: FilterDropdownProps) => (
          <DateRangeFilterDropdown {...props} />
        )
      },
      {
        dataIndex: "latestPayment.gateway",
        title: "Betalingsudbyder",
        className: "fit center",
        render: (v: string) => paymentGatewayTranslations[v],
        filters: Object.keys(paymentGatewayTranslations).map(x => ({
          value: x,
          text: paymentGatewayTranslations[x]
        }))
      },
      {
        dataIndex: "latestPayment.state",
        title: "Betalingsstatus",
        className: "fit center",
        render: (v: string, row: StatementListEntry) => (
          <PaymentStateLabel
            tag
            state={v}
            isProcessing={row.state !== "Processed"}
            fallbackValue={row.sourcePaymentInfo === "PBS" ? "PBS" : undefined}
          />
        ),
        filters: Object.keys(paymentStateTranslations).map(x => ({
          value: x,
          text: paymentStateTranslations[x]
        }))
      },
      {
        className: "fit center",
        render: (v: undefined, row: StatementListEntry) => (
          <ButtonLink
            icon="eye"
            title="Vis Faktura"
            onClick={() => this.showInvoice(row)}
          >
            Specifikation
          </ButtonLink>
        )
      },
      {
        className: "fit",
        render: (v: undefined, row: StatementListEntry) => {
          let items = [
            <Menu.Item key="sendInvoice">
              <ButtonLink
                onClick={() => this.showEmailModal(row)}
                disabled={!row.links.emailInvoice}
              >
                E-mail faktura
              </ButtonLink>
            </Menu.Item>
          ];

          return (
            <Dropdown trigger={["click"]} overlay={() => <Menu>{items}</Menu>}>
              <ButtonLink icon="down-circle" />
            </Dropdown>
          );
        }
      }
    ];

    return (
      <div>
        <Table<StatementListEntry>
          rowKey="id"
          size="middle"
          loading={loadingStatements}
          dataSource={toJS(statementEntries)}
          columns={columns}
          pagination={statementPagination}
          onRequest={this.handleTableRequest}
        />
        <InvoicePreviewDrawer
          invoiceLink={showInvoice && showInvoice.links.invoice}
          visible={!!showInvoice}
          onClose={this.closeInvoice}
        />
        <EmailInvoiceModal
          statementListEntry={showEmail}
          store={this.props.pageStore}
          onClose={this.closeEmailModal}
        />
      </div>
    );
  }
}
