const currencyFormatter = new Intl.NumberFormat("da-DK", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const currency = (value: number) => currencyFormatter.format(value);

export const paymentGatewayTranslations: { [gateway: string]: string } = {
  QuickPay: "QuickPay",
  LeverandorService: "LeverandørService",
  Betalingsservice: "Betalingsservice"
};

export const paymentStateTranslations: { [state: string]: string } = {
  Ignored: "Ignoreret",
  Initiated: "Afventer",
  Approved: "Godkendt",
  Paid: "Betalt",
  ChargedBack: "Tilbageført",
  Cancelled: "Annulleret"
};

export const quickPayPaymentStateTranslations: { [state: string]: string } = {
  Initiated: "Afventer",
  AuthorizationPending: "Afventer autorisation",
  AuthorizationFailed: "Autorisation fejlet",
  Authorized: "Autoriseret",
  CapturePending: "Afventer capture",
  CaptureFailed: "Capture fejlet",
  Captured: "Hævet på kort",
  Cancelled: "Annulleret"
};

export const quickPaySubscriptionStateTranslations: {
  [state: string]: string;
} = {
  Initiated: "Afventer kunde",
  Authorized: "Autoriseret"
};

export const leverandorServicePaymentStateTranslations: {
  [state: string]: string;
} = {
  Initiated: "Afventer",
  Registered: "Registreret hos NETS",
  Processed: "Betalt",
  Failed: "Fejl",
  ChargeBack: "Tilbageført",
  Cancelled: "Annulleret"
};

export const leverandorServiceSubscriptionStateTranslations: {
  [state: string]: string;
} = {
  Initiated: "Afventer kunde",
  AwaitingRegistration: "Afventer",
  AwaitingCancellation: "Afventer",
  Cancelled: "Annulleret"
};

export const lesseeRoleTranslations: { [role: string]: string } = {
  Private: "Privat",
  Business: "Erhverv"
};

export const paymentMethodTranslations: { [paymentMethod: string]: string } = {
  FIK: "FI-Kort",
  KB: "Betalingskort",
  LS: "LeverandørService",
  PBS: "Betalingsservice"
};
