import React from "react";
import { Row, Col } from "components";
import { Form, Input, SubmitButton } from "@jbuschke/formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";

interface Props {
  onSubmit: (values: FormValues) => void;
}

export interface FormValues {
  bankRegistrationNum: string;
  bankAccountNum: string;
}

const FormValuesSchema = Yup.object<FormValues>().shape({
  bankRegistrationNum: Yup.string()
    .length(4, "Skal bestå af 4 heltal.")
    .matches(/^\d+$/, "Skal bestå af 4 heltal.")
    .required("Påkrævet"),
  bankAccountNum: Yup.string()
    .max(10, "Max 10 heltal.")
    .matches(/^\d+$/, "Max 10 heltal.")
    .required("Påkrævet")
});

const LeverandorServiceSubscribeForm: React.FC<Props> = ({ onSubmit }) => (
  <Formik<FormValues>
    onSubmit={async (values, actions) => {
      await onSubmit(values);
      actions.setSubmitting(false);
    }}
    validationSchema={FormValuesSchema}
    initialValues={{ bankRegistrationNum: "", bankAccountNum: "" }}
    render={() => (
      <Form>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item name="bankRegistrationNum" label="Registreringsnr.">
              <Input name="bankRegistrationNum" />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item name="bankAccountNum" label="Kontonr.">
              <Input name="bankAccountNum" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton block>Tilmeld LeverandørService</SubmitButton>
          </Col>
        </Row>
      </Form>
    )}
  />
);

export default LeverandorServiceSubscribeForm;
