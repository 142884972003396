import { UserManager } from "oidc-client";

const redirectUri = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
  }/login/callback`;

const silentRenewUri = `${window.location.protocol}//${
  window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/login/renew`;

const userManagerConfig = {
  client_id: "fxm-web",
  redirect_uri: redirectUri,
  response_type: "token id_token",
  scope: "openid profile fxm-api fxm-ids-api",
  authority: process.env.REACT_APP_FXM_IDS_AUTHORITY,
  silent_redirect_uri: silentRenewUri,
  filterProtocolClaims: true,
  loadUserInfo: true
};

const userManager = new UserManager(userManagerConfig);

export default userManager;
