import NotFoundPage from "pages/notFound";
import ErrorPage from "pages/error";
import ForbiddenPage from "pages/forbidden";
import CreatePaymentSubscription from "pages/createPaymentSubscription";
import LesseesPage from "pages/lessees";
import AdminShell from "components/layout/adminShell";
import { LoginCallbackPage } from "pages/loginCallback";
import { LoginRedirectPage } from "pages/loginRedirect";
import LesseePage from "pages/lessee";
import PaymentsPage from "pages/payments";
import ImportBillingBasisPage from "pages/importBillingBasis";
import { LoginRenewPage } from "pages/loginRenew";
import CreatePaymentSubscriptionCompleted from "pages/createPaymentSubscriptionCompleted";
import { isAuthorized, redirectToFirstAuthorizedRoute } from "lib/routing";
import { Route } from "router5";
import { ComponentType } from "react";

export enum RouteName {
  Root = "root",
  Login = "login",
  LoginCallback = "LoginCallback",
  LoginRenew = "LoginRenew",
  Logout = "logout",
  Forbidden = "forbidden",
  NotFound = "notfound",
  Error = "error",
  CreatePaymentSubscription = "createPaymentSubscription",
  PaymentCompleted = "PaymentCompleted",
  Lessees = "Lessees",
  Lessee = "Lessee",
  Payments = "Payments",
  ImportBillingBasis = "ImportBillingBasis",
  Admin = "Admin",
  AdminLessorSpecific = "AdminLessorSpecific"
}

const sitemap: Array<SiteMapEntry> = [
  {
    name: RouteName.Root,
    path: "/",
    component: NotFoundPage
  },
  {
    name: RouteName.Forbidden,
    path: "/forbidden",
    component: ForbiddenPage
  },
  {
    name: RouteName.NotFound,
    path: "/notfound",
    component: NotFoundPage
  },
  {
    name: RouteName.Error,
    path: "/error",
    component: ErrorPage
  },
  {
    name: RouteName.LoginCallback,
    path: "/login/callback",
    component: LoginCallbackPage
  },
  {
    name: RouteName.LoginRenew,
    path: "/login/renew",
    component: LoginRenewPage
  },
  {
    name: RouteName.Login,
    path: "/login",
    component: LoginRedirectPage
  },
  {
    name: RouteName.PaymentCompleted,
    path: "/payments/completed",
    component: CreatePaymentSubscriptionCompleted
  },
  {
    name: RouteName.CreatePaymentSubscription,
    path: "/payments/:subscriptionId",
    component: CreatePaymentSubscription
  },
  {
    name: RouteName.Admin,
    path: "/admin",
    component: NotFoundPage, // User never sees this because hes redirected.
    canActivate: (router, dependencies) =>
      redirectToFirstAuthorizedRoute(router, dependencies)
  },
  {
    name: RouteName.AdminLessorSpecific,
    path: "/admin/:lessor",
    component: NotFoundPage, // User never sees this because hes redirected.
    canActivate: (router, dependencies) =>
      redirectToFirstAuthorizedRoute(router, dependencies)
  },
  {
    name: RouteName.Lessees,
    path: "/admin/:lessor/customers",
    text: "Kunder",
    component: LesseesPage,
    shellComponent: AdminShell,
    canActivate: (router, dependencies) => isAuthorized(router, dependencies),
    resourcePermission: "customers"
  },
  {
    name: RouteName.Lessee,
    path: "/admin/:lessor/customers/:id",
    component: LesseePage,
    shellComponent: AdminShell,
    canActivate: (router, dependencies) => isAuthorized(router, dependencies),
    resourcePermission: "customers"
  },
  {
    name: RouteName.Payments,
    path: "/admin/:lessor/payments",
    text: "Betalinger",
    component: PaymentsPage,
    shellComponent: AdminShell,
    canActivate: (router, dependencies) => isAuthorized(router, dependencies),
    resourcePermission: "payments"
  },
  {
    name: RouteName.ImportBillingBasis,
    path: "/admin/:lessor/invoicing",
    text: "Fakturering",
    component: ImportBillingBasisPage,
    shellComponent: AdminShell,
    canActivate: (router, dependencies) => isAuthorized(router, dependencies),
    resourcePermission: "billingBasis"
  }
];

export default sitemap;

export interface SubMenuEntry {
  text: string;
  routes: Array<SiteMapEntry>;
}

export interface MenuEntry extends AppRoute {
  text: string;
  showInOptionsMenu?: boolean;
}

export interface AppRoute extends Route {
  component: ComponentType<any>;
  shellComponent?: ComponentType<any>;
  resourcePermission?: string;
}

export type SiteMapEntry = SubMenuEntry | MenuEntry | AppRoute;

const getAppRoutesFromSiteMap = (sitemap: SiteMapEntry[]) => {
  return sitemap.reduce((acc, s) => {
    if ((s as AppRoute).path) {
      acc.push(s as AppRoute);
    } else if ((s as SubMenuEntry).routes) {
      acc.push(...getAppRoutesFromSiteMap((s as SubMenuEntry).routes));
    }

    return acc;
  }, new Array<AppRoute>());
};

const getMenuEntriesRecursive = (sitemap: SiteMapEntry[]) =>
  sitemap.reduce((acc, s) => {
    if ((s as MenuEntry).text) {
      acc.push(s as MenuEntry);
    } else if ((s as SubMenuEntry).routes) {
      const sub = s as SubMenuEntry;
      sub.routes = getMenuEntriesRecursive(sub.routes);
      acc.push(sub);
    }
    return acc;
  }, Array<SubMenuEntry | MenuEntry>());

const getRouteBreadcrumbsRecursive = (
  activeRoute: AppRoute,
  sitemap: SiteMapEntry[]
) => {
  return sitemap.reduce((acc, s) => {
    let me = s as MenuEntry;
    if (me.text && me.name === activeRoute.name) {
      acc.push((s as MenuEntry).text);
    } else if ((s as SubMenuEntry).routes) {
      const sub = s as SubMenuEntry;
      let match = getRouteBreadcrumbsRecursive(activeRoute, sub.routes);
      if (match.length > 0) {
        acc.push(sub.text);
        acc = acc.concat(match);
      }
    }
    return acc;
  }, new Array<string>());
};

export const appRoutes = getAppRoutesFromSiteMap(sitemap);
export const menuEntries = getMenuEntriesRecursive(sitemap);

export const getAppRoute = (routeName: string) =>
  appRoutes.find(x => x.name.toString() === routeName) as AppRoute;

export const getRouteBreadcrumbs = (activeRoute: AppRoute) =>
  getRouteBreadcrumbsRecursive(activeRoute, sitemap);
