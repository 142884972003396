import { PaymentShell } from "components/layout";
import { currency } from "lib/intl";
import React from "react";
import { PublicPayment } from "types/fxm";

const CreatePaymentSubscriptionCompleted = () => {
  const [payment, setPayment] = React.useState<PublicPayment | undefined>(
    undefined
  );

  React.useEffect(() => {
    const storedPaymentStr = window.sessionStorage.getItem("payment");
    if (storedPaymentStr) {
      const storedPayment: PublicPayment = JSON.parse(storedPaymentStr);
      setPayment(storedPayment);
    }
  }, []);

  if (!payment) {
    return null;
  }

  return (
    <PaymentShell lessor={payment.lessor}>
      <h3>Tak for din tilmelding</h3>
      <p>
        Vi hæver <b>kr. {currency(payment.amount)}</b> på dit betalingskort{" "}
        <b>d. {payment.dueDate}</b>.
      </p>
      <p>
        Alle fremtidige afregninger bliver automatisk hævet, og du vil modtage faktura pr. e-mail.
      </p>
    </PaymentShell>
  );
};

export default CreatePaymentSubscriptionCompleted;
