import React from "react";
import {
  leverandorServicePaymentStateTranslations,
  leverandorServiceSubscriptionStateTranslations
} from "lib/intl";
import { Tag } from "components";

const tagColor = (state: string) => {
  switch (state) {
    case "Processed":
      return "green";
    case "Failed":
      return "red";
    case "Cancelled":
      return "orange";
    default:
      return "blue";
  }
};

export default ({
  paymentState,
  subscriptionState,
  tag,
  style
}: {
  paymentState: string;
  subscriptionState: string;
  tag?: boolean;
  style?: React.CSSProperties;
}) => {
  let text = "";
  if (
    paymentState !== "Cancelled" &&
    (subscriptionState === "Initiated" ||
      subscriptionState === "AwaitingRegistration")
  ) {
    text = leverandorServiceSubscriptionStateTranslations[subscriptionState];
  } else {
    text = leverandorServicePaymentStateTranslations[paymentState];
  }

  return tag ? (
    <Tag style={style} color={tagColor(paymentState)}>
      {text}
    </Tag>
  ) : (
    <span style={style}>{text}</span>
  );
};
